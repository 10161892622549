import React from 'react';
import {ReactComponent as IconNotice} from '../../media/icon/notice.svg';// 공지사항 아이콘
/**
 * 공지사항 컴포넌트 내 헤더
 */
export class NoticeHeader extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            // TODO
        }
    }

    getHeaderTitle() {
        const pageType = this.props.pageType;
        const isSideBarRendering = this.props.isSideBarRendering;

        if (isSideBarRendering === true) {
            return '공지사항 목록';
        } else {
            switch (pageType) {
                case 'noticeList':
                    return '공지사항 목록';
                case 'noticeDetail':
                    return '공지사항 상세';
                default:
                    return '공지사항 상세';
            }
        }
    }

    getIconElement(iconTitle, ReactComponent) {
        return (
            <div>
        <span className='icon header-icon border-radius-md'>
          <ReactComponent/>
        </span>
                <span className='p-2 drag-disable'>{iconTitle}</span>
            </div>
        );
    }

    render() {
        return (
            <div id='iconWrap' className='d-flex'>
                <div className='me-auto p-2'>{this.getIconElement(this.getHeaderTitle(), IconNotice)}</div>
            </div>
        );
    }
}
