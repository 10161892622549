import { ModelClass } from "../ModelClass";

export class PrePaidOffset extends ModelClass{
  constructor(offset){
    super(offset);
    this.revenueMonth = 0;
    this.assetNameKo = '';
    this.itemNameKo = '';
    this.assetType = '';
    this.sumRecoupment = 0;
    this.sumMoneyOffset = 0;
    this.sumRecoupmentBalance = 0;

    this.init(offset);
  }

  init(offset){
    Object.keys(this).forEach(keyName=>{
      if(offset && offset?.[keyName]){
        switch(keyName){
          case 'revenueMonth':
            this[keyName] = this.getYearMonthStr(offset[keyName], this.fmt_yyyyMM_dash);
          break;

          case 'sumRecoupment':
          case 'sumMoneyOffset':
          case 'sumRecoupmentBalance':
            this[keyName] = this.getNumberWithDecimaPoint(offset[keyName],2);
            this[keyName] = this.getCurrencyValue(this[keyName]);
          break;

          default:
            this[keyName] = (offset[keyName])? offset[keyName] : '';
          break
        }
      }
    });
  }
}