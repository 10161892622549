import React from 'react';
import { Headers } from './Headers';
import { SettlmentInfo } from './SettlmentInfo';
import { TabPanel } from './TabPanel';

export class Statements extends React.Component{
  constructor(prop){
    /**
     *  
     */
    super(prop);
    this.righstHolder = this.props.rightsHolders;
    this.state={
      salesProof : null,
      revenueMonth : null,
      tabType : 'S' //Tap Type (S : 명세서), 
    }
  }

  setChagneSalesProof =(changedSalesProof, intRevenueMonth)=>{
    this.setState({
      salesProof : changedSalesProof,
      revenueMonth : intRevenueMonth
    });
  }

  setChangeTabType = (tabType)=>{
    this.setState({
      tabType : tabType,
      salesProof : this.state.salesProof,
      revenueMonth : this.state.revenueMonth
    });
  }

  render(){
    return(
    <div className='container-fluid py-4'>
      <Headers
        salesProof = {this.state.salesProof}
      />
      <div className='row mt-4'>
        <SettlmentInfo 
          rightsHolders = {this.righstHolder}
          salesProof = {this.state.salesProof}
          onStateChange = {this.setChagneSalesProof}
        />
      </div>
        <TabPanel 
          salesProof = {this.state.salesProof}
          revenueMonth = {this.state.revenueMonth}
          tabType = {this.state.tabType}
          onStateChange = {this.setChangeTabType}
        />
    </div>
  )}
}
