
import React from 'react';
import '../../css/common.css'

import { ReactComponent as IconCalculator } from '../../media/icon/calculator.svg';//정산 아이콘 
import { ReactComponent as IconDownload } from '../../media/icon/download.svg';//다운로드 아이콘
import { ReactComponent as PrintDownload } from '../../media/icon/printer.svg';//인쇄 아이콘
import { APIConfig } from '../../constans/APIConfig';
import AxiosClient from '../../util/AxiosClient';

export class Headers extends React.Component{
  
  constructor(prop){
    super(prop);
    this.state = {
      salesProof : this.props.salesProof
    }

    this.apiConfig = new APIConfig();
    this.apiCaller = new AxiosClient(this.apiConfig.API_URL);
  }

  getIconElement(iconTitle,ReactComponent){
    return(
      <div>
        <span className='icon header-icon border-radius-md'>
          <ReactComponent />
        </span>
        <span className='p-2 drag-disable'>{iconTitle}</span>
      </div>
    );
  }

  downloadStatements =(evt)=> {
      /**
       * 권리자 정산서 실시간 다운로드 개발 전/후에 따라 링크 다운로드 / API 요청 분기 처리
       * **/
      if (this.props.salesProof && this.props.salesProof?.calculateExcelFileUri) {
          if (this.props.salesProof?.gridFile == true) {
              return;
          } else {
              // 권리사 정산서 파일 다운로드 API 요청
              evt.preventDefault(); // 기본 클릭 동작을 막음 (페이지 이동 등)
              evt.stopPropagation(); // 이벤트 전파를 중단

              const queryStr = `revenueMonth=${this.props.salesProof.revenueMonth}&brandCode=${this.props.salesProof.brandCode.code}&rightId=${this.props.salesProof.rightId}&rightCode=${this.props.salesProof.rightCode}&rightName=${this.props.salesProof.rightName}`;
              const apiPath = `${this.apiConfig.DOWNLOAD.path}?${queryStr}`;

              this.apiCaller.getRequest(apiPath, (response) => {

                  switch (response.status) {
                      case 200: {
                          // Blob 데이터를 이용해 파일 다운로드 처리
                          const url = window.URL.createObjectURL(new Blob([response.data]));

                          // Content-Disposition 헤더에서 파일명 추출
                          const contentDisposition = response.headers['content-disposition'];

                          const fileNameRegex = /filename\*=(?:UTF-8'')?(.+)/;
                          const matches = fileNameRegex.exec(contentDisposition);

                          if (matches != null && matches[1]) {
                              // URL 디코딩하여 한글 파일명 복원
                              const fileName = decodeURIComponent(matches[1]);
                              const a = document.createElement('a');
                              a.href = url;
                              a.setAttribute('download', fileName); // 헤더에서 받은 파일명을 설정
                              document.body.appendChild(a);
                              a.click();
                              a.remove();
                          } else {
                              /* 간혹, header > content-disposition 값이 비어서 오는 경우, 수기로 파일명 생성 및 다운로드 수행 */
                              const revenueMonth = String(this.props.salesProof.revenueMonth).substr(2, 6);
                              const rightCode = this.props.salesProof.rightCode;
                              const rightName = this.props.salesProof.rightName;
                              const fileName = `${revenueMonth}_${rightCode}_${rightName}.xlsx`;
                              const a = document.createElement('a');
                              a.href = url;
                              a.setAttribute('download', fileName); // 헤더에서 받은 파일명을 설정
                              document.body.appendChild(a);
                              a.click();
                              a.remove();
                          }
                          break;
                      }

                      default:
                          alert(`상태코드[${response.status}], 응답[${response.data.body}] 권리사(${this.props.salesProof.rightName}) 정산서 파일 다운로드에 실패 했습니다.`);
                  }
              }, 'blob');
          }
          return;
      } else {
          alert('선택하신 정산월의 정산 정보가 없습니다.');
          evt.preventDefault(); // 기본 클릭 동작을 막음 (페이지 이동 등)
          evt.stopPropagation(); // 이벤트 전파를 중단
      }
  }

  print = (evt) => {
      window.print();
  }

  render(){
    
    const fileUrl = (this.props.salesProof && this.props.salesProof?.calculateExcelFileUri)? this.props.salesProof.calculateExcelFileUri : '';

    return(
        <div id='iconWrap' className='d-flex'>
            <div className='me-auto p-2'>{this.getIconElement('정산', IconCalculator)}</div>
            {/* <a className='p-2 ms-cursor-over' onClick={this.downloadStatements}>{this.getIconElement('상세내역', IconDownload)}</a> */}

            <a className='p-2 ms-cursor-over' onClick={this.print} print>{this.getIconElement('인쇄하기', PrintDownload)}</a>

            <a className='p-2 ms-cursor-over' onClick={this.downloadStatements}
               href={`${this.apiConfig.API_URL}${fileUrl}`} download
               target='_blank'>{this.getIconElement('상세내역', IconDownload)}</a>
        </div>
    );
  }
};
