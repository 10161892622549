import React from "react";
import { LoginView } from "./login/LoginView";
import {LayoutNavbar} from './layout/LayoutNavbar';
import {LayoutFooter} from './layout/LayoutFooter';
import { Statements } from "./Statements/Statements";
import AxiosClient from "../util/AxiosClient";
import { APIConfig } from "../constans/APIConfig";
import { RightsHolders } from "../model/RightsHolder";
import {NoticeComponent} from "./notice/NoticeComponent";
import {NoticePopUp} from "./common/NoticePopUp";
import {UrlParserUtil} from "../util/UrlParserUtil";

//componentDidMount	->	shouldComponentUpdate	->	Render	->	getSnapshotBeforeUpdate	->	componentDidUpdate
//shouldComponentUpdate 	->	Render	->	getSnapshotBeforeUpdate	->	componentDidUpdate 

export class Index extends React.Component{
  constructor(prop){
    super(prop);

    this.state = {
      isLogin : false,
      initComplete : false,
      isChangePasswordLastDate : false,
      sideBarMenuType: '' // 기본 값: 공백
    }

    this.apiConfig = new APIConfig();
    this.apiCaller = new AxiosClient(this.apiConfig.API_URL);
    this.HTTP_STATUS_CODE = this.apiConfig.HTTP_STATUS_CODE;
    this.rightsHolders = null;
    this.layoutNavbar = new LayoutNavbar(prop);

    this.noticeComponentRef = React.createRef();
    this.urlParserUtil = new UrlParserUtil();
  }

    // LayoutBar (메뉴 사이드바) 에서 상태 변경 요청을 받는 핸들러
  handleMenuChange = (newMenuType) => {
    this.setState({ sideBarMenuType: newMenuType });
  };

  setIsLogin =(loginSucces) =>{
    this.setState({
      isLogin : loginSucces
    });
  }

  getLoginView =()=>{
    return(<>
    <div id="loginWrap">
      <LoginView setIsLogin={this.setIsLogin}/>
    </div></>);
  }

  getSatementsView =(rightsHolders)=>{
    const { sideBarMenuType } = this.state;

    return(<>
    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none position-fixed" id="navbarBlur" navbar-scroll="false">
        <LayoutNavbar
            rightsHolders ={rightsHolders}
            sideBarMenuType={sideBarMenuType} onMenuChange={this.handleMenuChange}
        />
      </nav>
      <NoticePopUp onMenuChange={this.handleMenuChange} rightsHolders={rightsHolders}/>
      <div className="container-fluid py-5 custom-transition" id="containerFluid" style={{paddingLeft: '9rem'}}>
        <div id="content" className="row">
          <div className="card">
            <div id="root">
              {/* 사이드바 메뉴 클릭 > 메뉴 타입에 따라 렌더링할 컴포넌트 변경 */}
              {(sideBarMenuType === 'notice' || sideBarMenuType.startsWith('noticeDetail')) && <NoticeComponent ref={this.noticeComponentRef} sideBarMenuType={sideBarMenuType} rightsHolders={rightsHolders}/>}
              {(sideBarMenuType === 'settle') && <Statements rightsHolders ={rightsHolders}/>}
              {/* 사이드바 메뉴 클릭이 아닌, 브라우저 URL 클릭 시에 컴포넌트 로드 */}
              {(sideBarMenuType === '') ?
                  // 'notice' 로 시작하는 접근일 경우, 공지사항 컴포넌트 반환 / 그외에 기본 정산 내역 컴포넌트 반환
                  ((this.urlParserUtil.startsWithQueryParamPrefix('notice')) ? <NoticeComponent ref={this.noticeComponentRef} sideBarMenuType={sideBarMenuType} rightsHolders={rightsHolders}/> : <Statements rightsHolders ={rightsHolders}/>)
               : <></>}
            </div>
          </div>
        </div>
      </div>
      <footer className="footer pt-3">
        <LayoutFooter />
      </footer>
    </main>
    </>);
  }


  reqGetRightHolders(successCallBack){
    const getRightsHolders = this.apiConfig.RIGHTSHOLDERS;
    this.apiCaller.getRequest(getRightsHolders.path, (response)=>{
      if(successCallBack){
        successCallBack(response)
      }
    });
  }

  componentWillMount() {
    this.reqGetRightHolders(response=>{
      const responseHttpStatus = response?.status;
      if(responseHttpStatus && responseHttpStatus === this.HTTP_STATUS_CODE.SUCCESS_200_OK){
        const responseData = response?.data;
        this.rightsHolders = new RightsHolders(responseData?.body);
        this.setState({
          initComplete : true,
          isLogin : true,
          isChangePasswordLastDate : this.rightsHolders.changePasswordLastDate ? true : false
        })
      }else{
        this.setState({
          initComplete : true,
          isLogin : false,
          isChangePasswordLastDate : false
        })
      }
    });
  }

  componentDidMount() {
    //console.log('componentDidMount');
  }

  componentWillReceiveProps(nextProps) {
    //console.log('componentWillReceiveProps');
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log('shouldComponentUpdate');
    if (this.noticeComponentRef.current) {
      this.noticeComponentRef.current.updateSideBarRendering(true);
    }
    return true;
  }

  componentWillUpdate(nextProps, nextState) {
    //console.log('componentWillUpdate');
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log('componentDidUpdate');
  }

  componentWillUnmount() {
    //console.log('componentWillUnmount');
  }

  render(){
    const {initComplete ,isLogin, isChangePasswordLastDate} = this.state;
    return(<>
      {
        initComplete ? (
          isLogin ? (
          	isChangePasswordLastDate ? this.getSatementsView(this.rightsHolders) : this.layoutNavbar.changePassword('index')
          ) : this.getLoginView()
        ): null
      }
    </>);
  }
}