import React from 'react';
import {CommonTable} from "../../../common/CommonTable";
import {Modal} from '../../../common/Modal';
import {CarryOver} from '../../../../model/CarryOver';
import {TableUtil} from '../../../../util/TableUtil';
import {DataUtil} from "../../../../util/DataUtil";

export class RevenueCarryOver extends React.Component {
    constructor(prop) {
        super(prop);
        this.commTable = new CommonTable();
        this.dataUtil = new DataUtil();
        this.tableUtil = null;
        this.swal = null;
        this.tableHeadTitles = [
            '이월 사유',
            '전기이월(누적)'
        ];
        this.commTable.tableRowkeys = [
            'carryOverMemo',
            'sumForwardedAmount'
        ];
    }

    getEleTop() {
        const boaderClass = `border rounded-pill border-dark `;
        const msEventClass = `ms-cursor-over `;
        const textAndEtc = 'text-center px-4 bg-light '
        return (<>
            <div className='d-flex mt-4'>
                <div className="me-auto fw-bolder">전기이월 내역</div>
                {/*<div className={`${boaderClass} ${msEventClass}  ${textAndEtc}`} onClick={this.openCarryOverModal}>이월*/}
                {/*    상세*/}
                {/*</div>*/}
            </div>
        </>);
    }


    getTableRowElement(carryOverList) {
        let tableRow = [];
        if (carryOverList && carryOverList?.length) {
            carryOverList.forEach(carryOverInfo => {
                const carryOver = new CarryOver(carryOverInfo);
                if (carryOver.forwardedAmount != null && carryOver.forwardedAmount && Number(carryOver.forwardedAmount) != 0) {
                    const trElem = document.createElement('tr');
                    trElem.innerHTML = `<td>${carryOver.revenueMonth}</td>
                    <td class='text-end' title="${(carryOver.forwardedAmount != null && carryOver.forwardedAmount) ? carryOver.forwardedAmount : 0}">${this.dataUtil.currencyFormatter((carryOver.forwardedAmount != null && carryOver.forwardedAmount) ? carryOver.forwardedAmount : 0)}</td>
                    <td class='text-start'>${carryOver.carryOverMemo}</td>`;
                    tableRow.push(trElem);
                }
            });
        }
        return tableRow;
    }

    getCarrayOverHistory(carryOverList) {
        let tableElement;
        if (carryOverList && carryOverList?.length) {
            if (!this.tableUtil) {
                this.tableUtil = new TableUtil();
            }
            this.tableUtil.columnTitles = ['정산 월', '이월 금액', '이월 사유'];
            this.tableUtil.tableBodyRow = this.getTableRowElement(carryOverList);
            tableElement = this.tableUtil.getTableElement();
        } else {
            tableElement = `이월 내역이 없습니다.`
        }
        return tableElement;
    }

    getCarryOverSummary(carryOverList) {
        const carryOverSummary = {
            carryOverMemo: '-', /* 추후에, 이월/마감/조정 처리 기능 개발 이후에, 필드 값 매칭 */
            sumForwardedAmount: 0
        };

        if (carryOverList != null && carryOverList) {
            const forwardedAmount = (carryOverList[carryOverList.length - 1]["forwardedAmount"] != null && carryOverList[carryOverList.length - 1]["forwardedAmount"]) ? carryOverList[carryOverList.length - 1]["forwardedAmount"] : 0;
            carryOverSummary["sumForwardedAmount"] += forwardedAmount;
        }
        return carryOverSummary;
    }

    openCarryOverModal = () => {
        if (!this.swal) {
            this.swal = new Modal();
        }
        const carryOver = (this.props != null && this.props) ? (this.props.carryOver) : [];
        this.swal.settleHistoryModal('이월 내역', this.getCarrayOverHistory(carryOver));
    }

    render() {
        const carryOver = (this.props != null && this.props) ? (this.props.carryOver) : [];
        const tableHeaders = this.commTable.getTableHeads(this.tableHeadTitles);
        const tableRow = this.commTable.getTableRows([this.getCarryOverSummary(carryOver)]);
        return (<>
            {this.getEleTop()}
            {this.commTable.getTableElement(tableHeaders, tableRow)}
        </>);
    }
}