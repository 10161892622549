import React from 'react';
import Modal from 'react-modal';
import {NoticeDetail} from "../../model/notice/NoticeDetail";
import {APIConfig} from "../../constans/APIConfig";
import AxiosClient from "../../util/AxiosClient";

/**
 * 공지사항 팝업 컴포넌트
 */
export class NoticePopUp extends React.Component {
    constructor(props) {
        super(props);
        this.apiConfig = new APIConfig();
        this.apiCaller = new AxiosClient(this.apiConfig.API_URL);

        this.HTTP_STATUS_CODE = this.apiConfig.HTTP_STATUS_CODE;

        this.state = {
            isOpen: true, // 모달 열림/닫힘 상태를 컴포넌트 내에서 관리
        };
    }

    getPopUpDisplayLocalStorageName() {
        if (this.noticeId == null) {
            return "NoticePopUp-displayPopUp";
        } else {
            return `NoticePopUp-displayPopUp/${this.noticeId}`;
        }
    }

    clearPopUpDisplay() {
        // 현재 시간이 (팝업 다시 열지 않기 지정 시간) 을 지났을 경우에 clear
        const displayPopUpValue = localStorage.getItem(this.getPopUpDisplayLocalStorageName());

        if (displayPopUpValue != null && displayPopUpValue) {
            const curDate = this.utcDate(new Date());
            if (curDate > new Date(displayPopUpValue)) {
                localStorage.removeItem(this.getPopUpDisplayLocalStorageName());
                console.log("[clearPopUpDisplay] remove localStorage Item: NoticePopUp-displayPopUp");
            }
            console.log("[clearPopUpDisplay] curDate: " + curDate + ", displayPopUpValue: " + new Date(displayPopUpValue));
        }
    }

    // 사이드바 메뉴 변경을 상위 컴포넌트로 전달
    switchToSideBarMenuType = (type) => {
        if (type != null && type) {
            this.props.onMenuChange(type);
        }
    };

    utcDate(date) {
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return utcDate;
    }

    /**
     * [공지사항 팝업창 조회 API 호출]
     * 브라우저 시간대가 팝업 노출 기간에 포함 되는 경우, 팝업창이 노출 된다.
     */
    requestNoticePopUp() {
        // 공지사항 팝업
        const browserDate = new Date();
        const isoDateString = browserDate.toISOString(); // Ex: 2024-08-16T12:34:56.789Z
        const apiPath = `${this.apiConfig.NOTICE_POPUP.getPath(this.props.rightsHolders.rightId)}&browserTime=${isoDateString}`;

        console.log("[requestNoticePopUp] REQUEST API_PATH: " + apiPath);

        this.apiCaller.getRequest(apiPath, (res) => {
            // 세션 만료 (401) 발생 시에 로그인 요청
            if (res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                return;
            }

            if (res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                this.closeModal();
                return;
            }

            // 200 응답일 때에만 테이블 렌더링
            if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                try {
                    const responseBody = res.data?.body;
                    const noticeExposePopUpList = responseBody['exposePopUpNoticeList'];

                    /* 팝업창 표시 (이때, 다수의 noticeDetail 이 있을 경우에는 가장 최근 공지사항을 팝업창에 표시 */
                    if (noticeExposePopUpList != null && noticeExposePopUpList && noticeExposePopUpList.length) {
                        for (let i = 0; i < noticeExposePopUpList.length; i++) {
                            const noticeExposePopUp = noticeExposePopUpList[i];
                            const noticeDetail = new NoticeDetail(noticeExposePopUp);

                            if (noticeDetail != null && noticeDetail) {
                                console.log("[requestNoticePopUp] noticeDetail debug: " + noticeDetail.debug());
                                this.noticeId = noticeDetail['noticeId'];
                                this.clearPopUpDisplay();
                                this.setAndDisplayNoticePopUp(noticeDetail);
                                break;
                            } else {
                                this.closeModal();
                            }
                        }
                    } else {
                        // 팝업 노출 공지 사항이 없는 경우 Close
                        this.closeModal();
                    }
                } catch (error) {
                    console.error("[requestNoticePopUp], error: " + error);
                    this.closeModal();
                }
            } else {
                console.error("[requestNoticePopUp], not 200 STATUS CODE. [CODE]: " + res.status);
                this.closeModal();
            }
        });
    }

    /**
     * 팝업 1일 동안 보이지 않기 체크
     */
    setPopUpUnDisplay() {
        const curDate = this.utcDate(new Date()); // 현재 날짜와 시간을 가져옴
        const nextDate = new Date(curDate); // 현재 날짜를 복사하여 새로운 Date 객체 생성
        nextDate.setDate(curDate.getDate() + 1); // setDate 메서드를 사용하여 하루를 더함

        console.log("오늘 하루 동안 안보기 START");
        console.log("현재 날짜[UTC]: ", curDate);
        console.log("1일 뒤 날짜[UTC]: ", nextDate);
        localStorage.setItem(this.getPopUpDisplayLocalStorageName(), nextDate);
        console.log("[setPopUpUnDisplay] add localStorage Item: NoticePopUp-displayPopUp");
    }

    /**
     * 팝업 1일 동안 보이지 않기 여부 체크
     * true: 사용자가 지정한 안보기 날짜 범위에 포함 > 팝업 미표시
     * false: 사용자가 지정한 안보기 날짜 범위에 미포함 > 팝업 표시
     */
    checkPopUpDisplay(noticeDetail) {
        if (noticeDetail != null && noticeDetail) {
            const exposeStartDate = noticeDetail['exposeStartDate'];
            const exposeEndDate = noticeDetail['exposeEndDate'];
            const displayPopUpValue = localStorage.getItem(this.getPopUpDisplayLocalStorageName());

            console.log("[checkPopUpDisplay] exposeStartDate: "
                + exposeStartDate + ", exposeEndDate:" + exposeEndDate
                + ", curDate:" + this.utcDate(new Date()) + ", displayPopUpValue:" + displayPopUpValue);

            if (displayPopUpValue != null && displayPopUpValue) {
                if ((exposeStartDate != null && exposeStartDate) &&
                    (exposeEndDate != null && exposeEndDate)) {
                    // [아래와 같은 조건에서 팝업 미표시] - UTC 날짜 기준 체크
                    // 현재 시간은 이미 팝업 노출 시작-종료 일자에 포함
                    // 현재 시간 <= 사용자 지정 값 일 경우에, 팝업 미표시
                    if (this.utcDate(new Date()) <= new Date(displayPopUpValue)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            } else {
                // 하루 동안 다시 보이지 않기 버튼 클릭 안한 경우는 기본적으로 표시
                return false;
            }
        }
        // 응답 공지사항이 없는 경우 (버그 OR 에러) 에는 팝업 미표시
        return true;
    }

    setAndDisplayNoticePopUp(noticeDetail) {
        const noticeModal = document.getElementById("noticeModal");
        const noticePopUpBox = document.querySelector('#noticePopUpBox');

        if (noticePopUpBox != null && noticePopUpBox) {
            const noticeTitle = document.querySelector('#noticePopUpBox > #noticeTitle');
            if (noticeTitle != null && noticeTitle) {
                noticeTitle.innerText = noticeDetail.title;
            }

            const noticeContent = document.querySelector('#noticePopUpBox > #noticeContent');
            if (noticeContent != null && noticeContent) {
                noticeContent.innerHTML = noticeDetail.content;
            }
        }

        if (noticeModal != null && noticeModal) {
            // 팝업 1일 동안 안보기 여부 체크 (true 일 경우, 팝업 미표시)
            if (this.checkPopUpDisplay(noticeDetail) === false) {
                noticeModal.style.display = 'block';
            } else {
                this.closeModal();
            }
        }
    }

    componentWillMount() {
        // TODO
    }

    componentDidMount() {
        this.requestNoticePopUp();
    }

    openModal = () => {
        this.setState({isOpen: true});
    };

    closeModal = () => {
        this.setState({isOpen: false});
    };

    render() {
        return (
            <div>
                <Modal
                    id="noticeModal"
                    isOpen={this.state.isOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="공지사항"
                    style={{
                        content: {
                            display: 'none',
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: '90%', // 반응형을 위해 % 단위 사용
                            maxWidth: '45rem', // 최대 너비 설정
                            height: 'auto',
                            padding: '20px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            zIndex: '10001', // SweetAlert.js 보다 높은 z-index 설정
                        },
                        overlay: {
                            backgroundColor: 'transparent', // 오버레이를 투명하게 설정
                            zIndex: '10000', // SweetAlert.js 과 같은 z-index 또는 더 높게 설정
                        }
                    }}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false} // 오버레이 클릭으로는 모달이 닫히지 않도록 설정
                >
                    <div style={{textAlign: 'center'}} id="noticePopUpBox">
                        <h2 style={{
                            marginBottom: '20px',
                            fontSize: '24px',
                            fontWeight: 'bold',
                            wordWrap: 'break-word', // 긴 단어 줄바꿈
                        }} id="noticeTitle"></h2>
                        <div style={{
                            textAlign: 'left',
                            marginBottom: '20px',
                            fontSize: '16px',
                            lineHeight: '1.5',
                            overflow: 'scroll',
                            minHeight: '1rem',
                            maxHeight: '35rem',
                            wordWrap: 'break-word', // 긴 단어 줄바꿈
                        }} id="noticeContent"></div>
                        <button
                            onClick={this.closeModal} // 닫기 버튼 클릭 시 모달 닫기
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                backgroundColor: '#333',
                                color: '#fff',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                            }}
                        >닫기
                        </button>
                        <div
                            style={{marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0',
                                width: '100%',
                                justifyContent: 'center',
                            }}>
                                <input type="checkbox" onClick={(e) => {
                                    this.setPopUpUnDisplay();
                                    this.closeModal();
                                }}/><span style={{marginLeft: '0.5rem'}}>1일 동안 보이지 않기</span></label>
                            <button onClick={(e) => {
                                this.switchToSideBarMenuType(`noticeDetail/${this.noticeId}`);
                                this.closeModal();
                            }}
                                    style={{
                                        backgroundColor: '#333',
                                        color: '#fff',
                                        border: 'none',
                                        padding: '10px 20px',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        marginTop: '10px',
                                        width: '100%', // 버튼이 작은 화면에서는 전체 너비를 차지
                                        maxWidth: '200px', // 최대 너비 설정
                                    }}
                            >게시판 바로가기
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}