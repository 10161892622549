import { CommonTotalSum } from "../../../common/CommonTotalSum";

export class ReveneneTotalSum extends CommonTotalSum{
  constructor(prop){
    super(prop);
    this.totalSumTitle = '수익 합계';
    this.sumFontClassName = 'text-indigo';
  }

  render(){
    this.state = {
      totalSum : this.props.totalSum
    }
    return(this.getTotalSumElem());
  }
}