import {ModelClass} from "./ModelClass";

export class Adjustment extends ModelClass {
    constructor(adjustment) {
        super(adjustment)
        this.adjustmentAmount = null;
        this.memo = null;

        this.init(adjustment);
    }

    init(adjustment) {
        const keyList = Object.keys(this);

        keyList.forEach(keyName => {
            switch (keyName) {
                case 'adjustmentAmount':
                    this[keyName] = this.getCurrencyValue(adjustment[keyName]);
                    break;

                default:
                    this[keyName] = adjustment[keyName];
                    break;
            }
        });
    }
}