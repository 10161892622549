import React from "react";
import { Modal } from "../common/Modal";
import { APIConfig } from "../../constans/APIConfig";
import { UserLogin } from "../../model/auth/UserLogin";
import { ErrorModel } from "../../model/ErrorModel";
import AxiosClient from "../../util/AxiosClient";

export class LoginView extends React.Component{
  
  constructor(prop){
    super(prop);
    this.state = {
      setIsLogin : prop.setIsLogin
    }
    this.storageKeyName = 'userid';
    this.apiCaller = null;
    this.apiConfig = new APIConfig();
    this.HTTP_STATUS_CODE =  this.apiConfig.HTTP_STATUS_CODE;
    this.ERROR_TYPE = this.apiConfig.ERROR_TYPE;
    this.ERROR_CODE = this.apiConfig.ERROR_CODE;

    this.swal = null;
    this.userLoginInfo = null;
  }

  checkUserLoginInput(){
    const loginWrap = document.querySelector('div#loginWrap');    
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const emailInput = loginWrap.querySelector('input#email[type=email]');
    const passwordInput = loginWrap.querySelector('input#password[type=password]');
    let errorMessage = null;

    if(!passwordInput.value?.length){
      errorMessage = '패스워드를 입력해 주세요.';
    }

    if(!emailRegex.test(emailInput?.value)){
      errorMessage = '이메일 형식이 올바르지 않습니다.';
    }

    if(!emailInput.value?.length){
      errorMessage = '이메일을 입력해 주세요.'
    }

    if(errorMessage){
      if(!this.swal){
        this.swal = new Modal();
      }
      this.swal.alert(errorMessage);
      return false;
    }
    return true;

  }

  emailKeyDown =(evt)=>{
    if(evt.key === 'Enter'){
      if(this.checkUserLoginInput()){
        this.loginSubmit();
      }
      evt.preventDefault();
      return;
    }
  }
  passwordlKeyDown =(evt)=>{
    if(evt.key === 'Enter'){
      if(this.checkUserLoginInput()){
        this.loginSubmit();
      }
      evt.preventDefault();
      return;
    }
  }


  loginSuccess = (responseJson, response)=>{
    
    const httpStatusCode = responseJson?.status;
    const saveUserIdCheck = document.querySelector('input#flexCheckDefault');
    if(httpStatusCode && httpStatusCode === this.apiConfig.HTTP_STATUS_CODE.SUCCESS_200_OK){
      if(saveUserIdCheck){
        this.saveUserId(this.userLoginInfo.username, saveUserIdCheck.checked);
      }
      window.location.reload();
    }else{
      const userNameInput = document.querySelector('input#email');
      if(!saveUserIdCheck){
        userNameInput.value = '';
      }
      const userPasswordInput = document.querySelector('input#password');
      userPasswordInput.value = '';
      const errInfo = {
        CODE : this.ERROR_CODE.LOGIN.FAIL,
        MESSAGE : null
      }
      this.loginFailure(new ErrorModel( errInfo, this.ERROR_TYPE.API));
    }
  }

  loginFailure = (response)=>{
    if(!this.swal){
      this.swal = new Modal();
    }
    if(response && response.message){
      this.swal.alert(`${response.message}<br>[Code: ${response.code}]`);
    }
  }

  reqLogin = (userId, userPassword) =>{

    if(!this.apiConfig){
      this.apiConfig = new APIConfig();
    }

    if(!this.apiCaller){
      this.apiCaller = new AxiosClient(this.apiConfig.API_URL);
    }
    
    const loginApiConfig = this.apiConfig.LOGIN;
    this.userLoginInfo = new UserLogin(userId,userPassword);
    const userForm = new FormData();
    userForm.append('username', this.userLoginInfo.username);
    userForm.append('password', this.userLoginInfo.password)
    this.apiCaller.postRequest(`${loginApiConfig.path}`,  userForm, this.loginSuccess)
  }

  loginSubmit =(msEvent) => {
    if(this.checkUserLoginInput()){
      const loginWrap = document.querySelector('div#loginWrap');    
      const emailInput = loginWrap.querySelector('input#email[type=email]');
      const passwordInput = loginWrap.querySelector('input#password[type=password]');
      this.reqLogin(emailInput.value, passwordInput.value);
    }
  }

  getSavedUserId = ()=>{
    const storageKeyName = this.setBtoa(this.storageKeyName);
    try{
      return this.getAtob(localStorage.getItem(storageKeyName));
    }catch(excp){
      console.log(excp);
      return null;
    }
  }

  setBtoa(targetStr){
    let btoaStr = targetStr;
    btoaStr = btoa(btoaStr);
    return  btoaStr;
  }

  getAtob(targetStr){
    let atobStr = targetStr;
    if(atobStr && atobStr?.length){
      atobStr = atob(atobStr)
    }else{
      atobStr = null;
    }

    return atobStr;
  }

  saveUserId = (userEmail, isChecked) =>{
    try{
      const storageKeyName = this.setBtoa(this.storageKeyName);
      if(isChecked){
        localStorage.setItem(storageKeyName, this.setBtoa(userEmail));
      }else{
        localStorage.removeItem(storageKeyName);
      }
    }catch(excp){
      console.error(excp);
    }
  }

  render(){
    return(<>
      <div className="container d-block">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <div className="card bg-white">
              <div className="card-body p-5">
                <h2 className="text-center"><span>Mound</span><span>Media</span></h2>
                <h4 className="text-center">정산시스템</h4>
                <form className="mb-1 mt-md-4">
                  <h5 className="fw-bold mb-2 text-uppercase">Login</h5>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label ">Email address</label>
                    <input type="email" className="form-control" id="email" placeholder="ID를 입력해 주세요."
                           autoComplete="off" defaultValue={(this.getSavedUserId()) ? this.getSavedUserId() : ''}
                           onKeyDown={(e) => {
                             this.emailKeyDown(e)
                           }}/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label ">Password</label>
                    <input type="password" className="form-control" id="password" placeholder="비밀번호를 입력해주세요."
                           autoComplete="off" onKeyDown={(e) => {
                      this.passwordlKeyDown(e)
                    }}/>
                  </div>
                  <p className="small">
                    <a className="text-primary" href="forget-password.html">
                      {/**
                       * Forgot password?
                       */}
                    </a>
                  </p>
                  <div className="text-left">
                    <input type="checkbox" id="flexCheckDefault"
                           defaultChecked={(this.getSavedUserId()) ? true : false}/>
                    <label htmlFor="flexCheckDefault">아이디 저장</label>
                  </div>
                  <div className="d-grid">
                    <div className="btn btn-outline-dark" onClick={(e) => this.loginSubmit(e)}>로그인</div>
                  </div>
                </form>

                {/* 아이디/비밀번호 분실 계약 담당자 문의 연락처 */}
                <div style={{textAlign: 'center'}}>
                  <p className="mb-0 text-center">
                    <span style={{fontSize: "0.9rem"}}>로그인 문제 또는 아이디/비밀번호를 분실하신 경우<br/>아래의 각 브랜드 메일로 문의 부탁드립니다.</span>
                    <br/>

                    <u style={{display: 'inline-block', textDecoration: "none", marginTop: "0.5rem"}}>
                      <li style={{listStyle: 'none', textAlign: 'left'}}>
                        <span>포크라노스 : </span>
                        <a href="mailto:info@poclanos.com"
                           style={{textDecoration: "underline", marginLeft: "0.2rem"}}>info@poclanos.com</a>
                      </li>

                      <li style={{listStyle: 'none', textAlign: 'left'}}>
                        <span>보스토크 : </span>
                        <a href="mailto:music_royalty@vostok.kr"
                           style={{textDecoration: "underline", marginLeft: "0.2rem"}}>music_royalty@vostok.kr</a>
                      </li>

                      <li style={{listStyle: 'none', textAlign: 'left'}}>
                        <span>오즈 : </span>
                        <a href="mailto:ozz_account@moundmedia.co.kr"
                           style={{textDecoration: "underline", marginLeft: "0.2rem"}}>ozz_account@moundmedia.co.kr</a>
                      </li>
                    </u>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
  }
}