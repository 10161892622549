import { PrePaidOffset } from "./ContractPrePaid/PrePaidOffset";
import { ModelClass } from "./ModelClass";
import { Prepaid } from "./Prepaid";
import { CalcRightInfo } from "./SalseRevenue/CalcRightInfo";

export class ContractPrePaid extends ModelClass{
  constructor(contractPrePaid){
    super(contractPrePaid);
    this.calculateRightInfo = null;
    this.paymentList = null;
    this.offsetList = null;
    this.init(contractPrePaid);
  }

  init(contractPrePaid){
    Object.keys(this).forEach(keyName=>{
      if(contractPrePaid && contractPrePaid?.[keyName]){
        switch(keyName){

          case 'calculateRightInfo':
            this[keyName] = new CalcRightInfo(contractPrePaid[keyName]);
          break;

          case 'paymentList':
            if(contractPrePaid[keyName]?.length){
              this.paymentList =[];
              contractPrePaid[keyName].forEach(prepaid=>{
                this.paymentList.push(new Prepaid(prepaid));
              });
            }
          break;

          case 'offsetList':
            if(contractPrePaid[keyName]?.length){
              this.offsetList = [];
              contractPrePaid[keyName].forEach(contractInfo=>{
                this.offsetList.push(new PrePaidOffset(contractInfo));
              });
            }
          break;
  
  
          default:
          break;
        }
      }
    })
  }
}