
import React from 'react';
import { DateInfo } from './SettlmentInfo/DateInfo';
import { PaymentInfo } from './SettlmentInfo/PaymentInfo';
import { TransactionProof } from './SettlmentInfo/TransactionProof';
import '../../css/common.css'

export class SettlmentInfo extends React.Component{ 

  constructor(prop){
    super(prop);
    this.rightsHolders = this.props.rightsHolders
    this.royalty = this.rightsHolders.royalty;
    this.state ={
      revenueMonth :null,
      salesProof : null
    }
  }

  setRevenueMonth=(changedRevenueMonth, proof)=>{
    this.setState({
      revenueMonth : changedRevenueMonth,
      salesProof : proof
    });

    this.props.onStateChange(proof, changedRevenueMonth);
  }

  render(){

    return(
      <div className='card innerCard p-3'>
        <div className='row mt-2'>
          <div className="col-sm-5">
            <DateInfo 
              revenueMonth = {this.state.revenueMonth}
              onStateChange={this.setRevenueMonth}
              rightHolder = {this.rightsHolders}
            />
          </div>
          <div className="col-sm-7">
            <PaymentInfo 
              revenueMonth = {this.state.revenueMonth}
              salesProof = {this.state.salesProof}
            />
          </div>
        </div>
        <div className='d-flex flex-column m-2'>
          <TransactionProof
            salesProof={this.state.salesProof}
            revenueMonth={this.state.revenueMonth}
            rightHolder={this.rightsHolders}
          />
        </div>
      </div>
    );
  }
}