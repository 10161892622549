import {CommonTotalSum} from "../../../common/CommonTotalSum";

export class ModifyTotalSum extends CommonTotalSum {
    constructor(prop) {
        super(prop);
        this.totalSumTitle = '조정 합계';
        this.sumFontClassName = 'text-gray';
    }

    render() {
        if (!this.props.totalSum || !this.props.totalSum === 0) {
            this.sumFontClassName = 'text-dark';
        } else {
            this.sumFontClassName = 'text-dark';
        }
        return (this.getTotalSumElem());
    }
}