import { ModelClass } from "./ModelClass";

export class Prepaid extends ModelClass{
  constructor(prepaid){
    super(prepaid);
    this.payTime = null;
    this.amount = null;
    this.regAdminName = null;
    this.managerName = null;
    this.memo = null;
    this.init(prepaid)
  }

  init(prepaid){
    const prepaidKey = Object.keys(this);
    prepaidKey.forEach(keyName=>{
      switch(keyName){
        case 'payTime':
          //getStrDateToIntlDate
          if(prepaid?.[keyName]){
            const intDate = this.getStrDateToIntlDate(prepaid[keyName])
            this[keyName] = this.getYearMonthStr(intDate, this.fmt_yyyyMMdd_dot);
          }
        break;
        
        case 'amount':
          this[keyName] = this.getCurrencyValue(prepaid[keyName]);
        break;

        default:
          this[keyName] = prepaid[keyName];
        break;
      }
    })
  }
}