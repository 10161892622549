import React from 'react';
import { DataUtil } from '../../../../util/DataUtil';
import { CommonTable } from '../../../common/CommonTable';
import { TableUtil  } from '../../../../util/TableUtil';
import { Modal } from '../../../common/Modal';
import { Prepaid as PrepiadModel } from '../../../../model/Prepaid';
import { Offset as OffsetModel } from '../../../../model/Offset';
import { APIConfig } from "../../../../constans/APIConfig";
import AxiosClient from '../../../../util/AxiosClient';
import { ContractPrePaid } from '../../../../model/ContractPrePaid';

export class Prepaid extends React.Component{
  constructor(prop){
    super(prop);
    this.apiConfig = new APIConfig();
    this.apiCaller = new AxiosClient( this.apiConfig.API_URL);

    this.HTTP_STATUS_CODE = this.apiConfig.HTTP_STATUS_CODE;

    this.SALES_CONTRACT_PREPAID = this.apiConfig.SALES_CONTRACT_PREPAID;

    this.dataUtil = new DataUtil();
    this.swal = new Modal();
  
    this.commTable = new CommonTable();
    this.tableUtil = null;
    this.tableHeadTitles = [
      '계약 명',
      '전기잔액',
      '대상앨범 수익',
      '당기잔액',
      '상계금액'
    ];
    
    this.commTable.tableRowkeys = [
      'contractName', 
      'sumPrevRecoupmentBalance',
      'sumMoneySale',
      'sumRecoupmentBalance',
      'sumMoneyOffset'
    ]

    this.reqContractPathList = null;
    this.contractInfoList = null;
  }

  getCarrayOverTable(carryOverList){
    return `<table class='table table-bordered border-dark' style='font-size:1rem;'>
      <thead class='table-dark'>
        <tr>
          <th>정산 월</th>
          <th>이월 금액</th>
          <th>이월 사유</th>
        </tr>
      </thead>
      <tbody>${this.getTableRowElement(carryOverList)}     
      </tbody>
    </table>`;
  }

  getCarrayOverHistory(carryOverList){
    let tableElement;
    if(carryOverList && carryOverList?.length){
      tableElement = this.getCarrayOverTable(carryOverList);
    }else{
      tableElement = `이월 내역이 없습니다.`
    }
    return tableElement;
  }

  getPrepaidHistoryRow(prepaidHistory){
    let historyRow = null;
    if(prepaidHistory && prepaidHistory?.length){
      historyRow = [];
      prepaidHistory.forEach(historyInfo=>{
        const trElem = document.createElement('tr');
        trElem.innerHTML = `<td>${historyInfo.payTime}</td>
          <td title="${historyInfo.amount}">${this.dataUtil.currencyFormatter(historyInfo.amount)}</td>
          <td>${(historyInfo.memo)? historyInfo.memo : '-'}</td>`;
        historyRow.push(trElem);
      })
    }

    return historyRow;
  }

  getPrepaidHistoryTable(paymentList){
    const historyTable = new TableUtil();
    historyTable.columnTitles = ['지급일', '지급 금액', '메모'];
    historyTable.tableBodyRow = this.getPrepaidHistoryRow(paymentList);
    historyTable.tableStyle = 'font-size:0.8rem';
    return historyTable.getTableElement();
  }

  getOffsetHistoryRow(offsetHistory){
    let historyRow = null;
    if(offsetHistory && offsetHistory?.length){
      historyRow = [];
      offsetHistory.forEach(historyInfo=>{
        const trElem = document.createElement('tr');
        trElem.innerHTML = `<td>${historyInfo.revenueMonth}</td>
          <td>${historyInfo.assetType}</td>
          <td>${historyInfo.assetNameKo}</td>
          <td>${historyInfo.itemNameKo}</td>
          <td title="${historyInfo.sumMoneyOffset}">${this.dataUtil.currencyFormatter(historyInfo.sumMoneyOffset)}</td>
          <td title="${historyInfo.sumRecoupmentBalance}">${this.dataUtil.currencyFormatter(historyInfo.sumRecoupmentBalance)}</td>`;
        historyRow.push(trElem);
      })
    }
    return historyRow;
  }

  getOffsetHistoryTable(offsetList){
    const historyTable = new TableUtil();
    historyTable.columnTitles = ['정산 월', '구분', '앨범 명', '트랙명', '상계 금액','잔액'];
    historyTable.tableBodyRow = this.getOffsetHistoryRow(offsetList);
    historyTable.tableStyle = 'font-size:0.8rem';
    return historyTable.getTableElement();
  }

  getTabPane(contractInfoList){

    if(contractInfoList?.length){

      const tabContent = document.createElement('div');
      tabContent.className ='tab-content';
      tabContent.style = 'width:100%;'
      tabContent.id = 'v-pills-tabContent'
      const tabPaneDefaultClass = `tab-pane fade`;

      contractInfoList.forEach((contractInfo, idx)=>{
        const contractPrePaid = new ContractPrePaid(contractInfo)
        const tabPane = document.createElement('div');
        tabPane.className = (idx=== 0)? `${tabPaneDefaultClass} show active` : `${tabPaneDefaultClass}`;
        const targetId= `v-pills-${idx}`; 
        tabPane.id = `${targetId}`;
        tabPane.role = 'tabpanel';
        tabPane.ariaLabelLedby = `v-pills-tab-${idx}`;
        tabPane.tabIndex = '0';
    
        if(contractPrePaid.paymentList?.length){
          tabPane.innerHTML += this.getPrepaidHistoryTable(contractPrePaid.paymentList);
        }
        
        if(contractPrePaid.offsetList?.length){
          tabPane.innerHTML += this.getOffsetHistoryTable(contractPrePaid.offsetList);
        }

        tabContent.appendChild(tabPane);
      })
      return tabContent;
    }else{
      return null; 
    }
  }

  getTabList(calculateContract){
    const talListElem = document.createElement('div');
    talListElem.id = 'pills-tab';
    talListElem.className = 'nav nav-pills';
    talListElem.role = 'tablist';
    talListElem.ariaOrientation = 'vertical';
    talListElem.style = 'max-height:300px; width:300px; overflow-y:auto;';

    if(calculateContract && calculateContract?.length){
      const buttonDefaultClass = 'nav-link my-1';
      calculateContract.forEach((contract,idx)=>{
        const buttonElem = document.createElement('button');
        buttonElem.className = (idx === 0)? `${buttonDefaultClass} active` : buttonDefaultClass;
        buttonElem.id = `v-pills-tab-${idx}`;
        buttonElem.type = 'button';
        buttonElem.role = 'tab';
        const targetPaneName = `v-pills-${idx}`;
        buttonElem.dataset.bsTarget = `#${targetPaneName}`;
        buttonElem.dataset.bsToggle = 'pill'
        buttonElem.ariaControls = `${targetPaneName}`;
        buttonElem.ariaSelected = (idx === 0)? 'true' : 'false';
        
        const contractName = `계약명 : ${contract?.contractName}`;
        const nameStyle = `width: 170px;
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        `

        const contractId = `계약번호 : ${contract?.contractId}`;
        const idStyle = `width: 170px;
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left`
        buttonElem.title = `${contractName}`;

        buttonElem.innerHTML = `<div style="${nameStyle}">${contractName}</div><div style="${idStyle}">${contractId}</div>`;
        talListElem.appendChild(buttonElem);
      });
    }
    return talListElem;
  }


  getPrepaidContractInfo(requestPathList,successCallBack){
	const requestPath = requestPathList.pop();
    this.apiCaller.getRequest(requestPath,(response)=>{
      if(successCallBack){
        successCallBack(response);
      }
    });
  }

  getPrepaidContractInfoCallBack =(response)=>{
    if(response.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK){
      const responseData = response?.data;
      this.contractInfoList.push(responseData?.body);

      if(this.reqContractPathList?.length >= 1){
        this.getPrepaidContractInfo( this.reqContractPathList, this.getPrepaidContractInfoCallBack)
      }else{
        this.openContractModal();
      }
    }else{
      this.swal.alert( '계약정보 요청에 실패했습니다.');
    }
  }


  openContractModal =()=>{
    const calculateContract = this.props.calculateContract;
    const wrapElement = document.createElement('wrap');
    const navWrap = document.createElement('div');
    navWrap.className = 'd-flex align-items-start';
    const tablList = this.getTabList(calculateContract);
    navWrap.appendChild(tablList);
    const tabPane = this.getTabPane(this.contractInfoList);
    navWrap.appendChild(tabPane);
    wrapElement.appendChild(navWrap);
    this.swal.settleHistoryModal('선급금 상계 내역', wrapElement.innerHTML, '70em');
  }

  getContractInfo=()=>{
    const calculateContract = this.props.calculateContract;
    const revenueMonth = this.props.salesProof.revenueMonth;
    if(calculateContract?.length){
      this.reqContractPathList =[];
      this.contractInfoList = [];
      calculateContract.forEach(contractInfo=>{
        this.reqContractPathList.push (this.SALES_CONTRACT_PREPAID.getPath(contractInfo.contractId, revenueMonth));
      });
      
      const tmpRequestPath = this.reqContractPathList.reverse();	// 배열을 뒤바뀌게 해야 pop() 호출시 순서대로 호출됨.
      this.getPrepaidContractInfo( tmpRequestPath, this.getPrepaidContractInfoCallBack)
    }else{
      this.swal.alert( '계약 정보가 없습니다.');
    }
  }

  getEleTop(){
    const boaderClass = `border rounded-pill border-dark`;
    const msEventClass = `ms-cursor-over`;
    const textAndEtc = 'text-center px-4 bg-light';
    return(<>
      <div className='d-flex mt-4'>
        <div className="me-auto fw-bolder">선급금 상계</div>
        <div className= {`${boaderClass} ${msEventClass} ${textAndEtc}`} onClick={this.getContractInfo}>선급 상세</div>
      </div>
    </>);
  }
  
  getElemTable(){
    let totalSum = 0;
    if(this.props.calculateContract.length){
      totalSum = this.props.calculateContract.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.sumMoneyOffset;
      }, 0); 
    }

    const tableHeaders = this.commTable.getTableHeads(this.tableHeadTitles)
    const tableRows = this.commTable.getTableRows(this.props.calculateContract);
    const tableFooter = this.commTable.getFooterInfo('상계총액', totalSum)
    return(<>
      {this.commTable.getTableElement(tableHeaders,tableRows,tableFooter)}
    </>);
  }

  render(){
    return(<>
      {this.getEleTop()}
      {this.getElemTable()}
    </>);
  }
}