import { CommonShare } from "../../../common/CommonShare";

export class RevenueShare extends CommonShare{
  constructor(prop){
    super(prop);
    this.shareTitle ='받은 분배 내역';
    this.tableColsTitles = [
      '분배한 권리자',
      '앨범 명',
      '트랙 명',
      '스트리밍 수',
      '다운로드 수',
      '기타/판매 수',
      '분배 %',
      '분배금액'
    ]
    this.commTable.tableRowkeys =[
      'rightName',
      'assetNameKo',
      'itemNameKo',
      'cntStream',
      'cntDownload',
      'cntEtc',
      'revenueRate',
      'sumRevenueMoney'
    ]
  }

  render(){
    let totalSumRevenueMoney = 0;
    if(this.props.getCalculateRevenue?.length){
      totalSumRevenueMoney  = this.props.getCalculateRevenue.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.sumRevenueMoney;
      }, 0);
    }

    this.state = {
      shareList  : this.props.getCalculateRevenue,
      sum : totalSumRevenueMoney
    }
    if(this.state.shareList?.length){
      return(<>
        {this.getEleTop()}
        {this.getElemTable()}
      </>);
    }else{
      return(<></>);
    }
  }
}