import { ModelClass } from "./ModelClass";

export class SalesProof extends ModelClass{
  constructor(salesProof){
    super(salesProof);
    this.revenueMonth = null; //분배월
    this.brandCode = null;
    this.transferAmount = null;//공급가액
    this.vat = null;//소득세/부가세
    this.residentTax = null;//주민세(지방세)
    this.preTransferAmount = null;//(전월 실 지급액 = 공급가액 + 소득세/부가세 + 주민세(지방세))
    this.royaltyType = null;//정산타입
    this.bankCode = null;//거래 은행 코드
    this.bankName = null;//거래 은행 명
    this.bankAccountNum = null;// 계좌번호
    this.bankBranchName = null;//입금계좌번호 해외사업자 : 지점명(Branch Name)
    this.businessLicenseNum = null;//사업자 번호
    this.companyName = null;//사업자명
    this.contractId = null;
    this.calculateExcelFileUri = null;//정산서 파일 URI
    this.gridFile = false; // 권리자 정산서 (grid File) 생성 여부
    this.taxRate = null;//입력 세율
    this.paymentAmount = null;//지급 총액
    this.rightId = null; // 권리자 ID
    this.rightCode = null; // 권리자 CODE
    this.rightName = null; // 권리자 명
    this.init(salesProof);
  }

  init(salesProof){
    Object.keys(this).forEach(keyname=>{
      switch(keyname){
        case 'revenueMonth':
        case 'contractId':
          this[keyname] = this.getIntNum(salesProof, keyname);
        break;

        case 'transferAmount':
        case 'vat':
        case 'residentTax':
        case 'preTransferAmount':
        case 'paymentAmount':
          const intAmount = (salesProof && salesProof[keyname])? salesProof[keyname] : 0;
          this[keyname] = intAmount;
        break;

        case 'gridFile':
          if(salesProof && salesProof[keyname]) {
            this[keyname] = salesProof[keyname];
          }
          break;

        default:
          if(salesProof && salesProof[keyname]){
            this[keyname] =  salesProof[keyname];
          }  
        break;
      }
    });
  }
}