import {ModelClass} from "../ModelClass";

/**
 * 공지사항 리스트 내 아이템
 */
export class NoticeItem extends ModelClass {
    constructor(noticeItem) {
        super(noticeItem);
        this.noticeId = 0; // 공지사항 (번호)
        this.noticeType = null; // 공지사항 유형
        this.level = 0; // 공지사항 노출 레벨
        this.title = null; // 제목
        this.publicDate = null; // 웹명세서 공개 날짜
        this.regDate = null; // 등록일
        this.callBack = null; // 콜백 (공지사항 상세 페이지 이동)
        this.init(noticeItem);
    }

    init(noticeItem) {
        Object.keys(this).forEach(keyName => {
            switch (keyName) {
                case 'regDate':
                    if (noticeItem?.[keyName]) {
                        this[keyName] = this.getDateToStr(noticeItem[keyName]);
                    }
                    break;

                default:
                    if (noticeItem?.[keyName]) {
                        this[keyName] = noticeItem[keyName];
                    }
                    break;
            }
        });
    }

    setCallBack(callBack) {
        this.callBack = callBack;
    }

    getCallBack() {
        return this.callBack;
    }
}