import {ModelClass} from "../../ModelClass";

/**
 * 판매처 별 내역
 */
export class SaleSiteRevenueItem extends ModelClass {
    constructor(revenueItem) {
        super(revenueItem);

        this.revenueMonth = 0; //분배월
        this.calculateMonth = 0;//정산월
        this.serviceMonth = 0;//서비스월
        this.siteName = null;// 서비스/판매처 명
        this.assetType = null; // 카탈로그 구분
        this.cntStream = 0;//스트리밍 수
        this.cntDownload = 0;//다운로드 수
        this.cntEtc = 0;//기타/판매 수
        this.sumRevenueMoney = 0; // 판매액 (매출)
        this.sumRevenueFee = 0; // 수수료
        this.sumRevenueTotal = 0; // 수익 (수수료 제외)
        this.init(revenueItem);
    }

    init(revenueItem) {
        Object.keys(this).forEach(keyName => {
            switch (keyName) {
                case 'revenueMonth':
                case 'calculateMonth':
                case 'serviceMonth':
                    if (revenueItem?.[keyName]) {
                        this[keyName] = this.getYearMonthStr(revenueItem[keyName], this.fmt_yyyyMM_dot);
                    }
                    break;

                case 'cntStream':
                case 'cntDownload':
                case 'cntEtc':
                case 'sumRevenueMoney':
                case 'sumRevenueFee':
                case 'sumRevenueTotal':
                    this[keyName] = this.getNumberWithDecimaPoint(revenueItem?.[keyName], 2);
                    break;

                default:
                    if (revenueItem?.[keyName]) {
                        this[keyName] = revenueItem[keyName];
                    }
                    break;
            }
        });
    }
}