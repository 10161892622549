import React from "react";
import CommonPagination from "../../common/CommonPagination";
import {DataUtil} from "../../../util/DataUtil";

/**
 * 공지사항 테이블 컴포넌트 유틸
 */
export class NoticeTableComponentImpl {
    constructor() {
        this.dataUtil = new DataUtil();
    }

    renderTableComponent(state) {
        const {
            tableType,
            titleElem,
            tableKey,
            tableRow,
            tableRowKeys,
            footerInfo,
            classData,
            reRender,
            pagination
        } = state;

        /**
         * setState 를 사용하여 상태 업데이트 후 동적으로 직접 컴포넌트 렌더링 하는 경우에는 아래의 에러를 방지하도록 직접 테이블 내에 데이터를 주입
         *
         * [ERROR]
         * Objects are not valid as a React child (found: object with keys {code, desc, enumName}).
         * If you meant to render a collection of children, use an array instead.
         * **/

        /* 헤더 정보 계산 */
        const tableHeaders = [];

        if (titleElem != null && titleElem) {
            for (let i = 0; i < titleElem.length; i++) {
                tableHeaders.push(<th title={titleElem[i]}>{titleElem[i]}</th>);
            }
        }

        /* 행 정보 계산 */
        const tableRowList = [];
        const tableKeyMap = (tableKey != null && tableKey) ? new Map(tableKey.map(key => [key, key])) : {};

        if (tableRow != null && tableRow) {
            for (let i = 0; i < tableRow.length; i++) {
                const noticeItem = tableRow[i];
                const tdList = [];

                Object.entries(noticeItem).map(([key, value], index) => {
                    const tableKeyValue = tableKeyMap.get(key);

                    if (tableKeyValue != null) {
                        if (value != null) {
                            const numberFormatValue = (typeof value === 'number') ? this.dataUtil.currencyFormatter(value) : value;

                            switch (tableKeyValue) {
                                case 'noticeId':
                                    const level = noticeItem['level'];
                                    if (level > 0) { // 최상단 공지의 경우, 중요 아이콘 표시
                                        tdList.push(<td title={'중요 공지사항'}>
                                            <button class={`beige-badge`}>중요</button>
                                        </td>);
                                    } else {
                                        tdList.push(<td title={value}>{numberFormatValue}</td>);
                                    }
                                    break;

                                case 'title':
                                    tdList.push(<td title={value}>
                                        <a class={`basic-aTag`} onClick={(e) => {
                                            const callback = noticeItem['callBack'];
                                            const noticeId = noticeItem['noticeId'];

                                            if (callback != null && callback) {
                                                // 공지사항 상세 페이지 이동
                                                callback(noticeId);
                                            }
                                        }}>{numberFormatValue}</a>
                                        {this.checkNewNotice(noticeItem['publicDate'])}
                                    </td>);
                                    break;

                                case 'noticeType':
                                case 'regDate':
                                    tdList.push(<td title={value}>{numberFormatValue}</td>);
                                    break;

                                default:
                                    break;
                            }
                        } else {
                            tdList.push(<td>{''}</td>);
                        }
                    }
                    return null;
                });
                tableRowList.push(<tr>{tdList}</tr>);
            }
        }

        return (
            <>
                <div className={classData.tableWrap}>
                    <table className={classData.table}>
                        <thead className={classData.tableHead}>
                        <tr className="border-bottom border-black">
                            {tableHeaders}
                        </tr>
                        </thead>
                        <tbody className={classData.tableBody}>
                        {tableRowList}
                        </tbody>
                        {[]}
                    </table>
                </div>

                {
                    (pagination != null && pagination) ?
                        <CommonPagination
                            totalItems={pagination.totalItems}
                            itemCountPerPage={pagination.itemCountPerPage}
                            pageCount={pagination.pageCount}
                            currentPage={pagination.currentPage}
                            onPageChange={pagination.onPageChange}
                        /> : <></>
                }
            </>
        );
    }

    /**
     * 신규 공지 사항 여부 체크
     * (현재 브라우저 시간대 - 공지사항 공개 시간) <= 하루(24시) : NEW 공지 사항
     * (현재 브라우저 시간대 - 공지사항 공개 시간) > 하루(24시) : 일반 공지 사항
     * @param publicDate
     * @returns {Element}
     */
    checkNewNotice(publicDate) {
        const curDate = new Date()
        const curUtcDate = new Date(curDate.getTime() + curDate.getTimezoneOffset() * 60000);

        // (현재 브라우저 시간 - 해당 공지사항 웹 명세서 공개 시간) 값이 하루24시간 보다 크면 (즉, 공지사항이 공개 된지 하루 이상 지나면)
        if ((Math.abs(curUtcDate - new Date(publicDate)) / (1000 * 60 * 60 * 24)) >= 1) {
            return <></>;
        } else {
            return <span style={{
                border: '4px solid #f5b84b',
                backgroundColor: '#f5b84b',
                fontSize: '0.5rem',
                color: '#fff',
                borderRadius: '10px',
                transitionDuration: '0.3s',
                marginLeft: '0.3rem',
                boxShadow: '3px 3px 3px #eeeeee;'
            }}>NEW</span>;
        }
    }
}