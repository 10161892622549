import { ModelClass } from "../ModelClass";

export class CalcRightInfo extends ModelClass{
  constructor(calcRightInfo){
    super(calcRightInfo)
    this.transferAmount = 0; //공급가액
    this.vat = 0;// 소득세,부가세
    this.residentTax = 0;//지방세 
    this.taxRate = 0; //입력세율
    //소득세 = 입력세율 * 91
    //지방세 = 입력세율 * 9
    //부가세 = 입력세율 
  }
}