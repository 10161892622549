import React from 'react';
import {CommonTable} from "../../../common/CommonTable";

export class ModifyHistory extends React.Component {
    constructor(prop) {
        super(prop);
        this.commTable = new CommonTable();
        this.tableHeadTitles = [
            '조정 사유',
            '조정 금액'
        ]
        this.commTable.tableRowkeys = [
            'memo',
            'adjustmentAmount'
        ]
    }

    render() {
        const tableHeaders = this.commTable.getTableHeads(this.tableHeadTitles);
        const tableRow = this.commTable.getTableRows(this.props.adjustmentList);
        return (<>
            {this.commTable.getTableElement(tableHeaders, tableRow)}
        </>);
    }
}