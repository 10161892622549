import React from 'react';
import {DataUtil} from '../../util/DataUtil';
import {TableComponent} from "../table/TableComponent";

export class CommonTable extends React.Component {
    constructor(prop) {
        super(prop);
        this.dataUtil = new DataUtil();
        this.tableHeadTitles = [];
        this.tableRowkeys = [];
        this.tableRow = [];
        this.tableColsTitles = null;
        this.classData = {
            tableWrap: 'card h-100 mt-2 px-4 py-3 bg-light text-center',
            table: '',
            tableHead: '',
            tableBody: '',
            tableFooter: 'border-top border-black',
        }
        this.tableComponentRef = React.createRef();
    }

    getTableHeads(titleStringList, addClassName = 'border-bottom border-black') {
        const titleTh = [];
        this.tableColsTitles = titleStringList;
        this.tableColsTitles.forEach((titleStr, idx) => {
            const thElem = <th key={idx}>{titleStr}</th>;
            titleTh.push(thElem);
        });
        return (<>
            <tr className={addClassName}>
                {titleTh}
            </tr>
        </>);
    }

    getCellInfo(rowInfo) {
        let tdDatas = [];
        this.tableRowkeys.forEach((keyName, idx) => {
            let cellData = null;
            switch (keyName) {
                case 'shareRate':
                case 'revenueRate':
                    cellData = `${Math.floor(rowInfo[keyName] * 100) / 100}%`; // 비율 표기
                    break;

                default:
                    if (typeof rowInfo[keyName] === 'number') {
                        cellData = this.dataUtil.currencyFormatter(rowInfo[keyName]);
                    } else {
                        cellData = rowInfo[keyName];
                    }
                    break;
            }
            tdDatas.push(<td key={idx} title={rowInfo[keyName]}>{(cellData) ? cellData : ''}</td>);
        })
        return tdDatas;
    }

    getTableRows(rowList, trClassName) {
        let elemTableRow = [];
        if (rowList?.length) {
            rowList.forEach((rowInfo, idx) => {
                const trElem = <tr className={trClassName} key={idx}>{this.getCellInfo(rowInfo)}</tr>;
                elemTableRow.push(trElem);
            });
        }

        return elemTableRow;
    }

    getFooterInfo(footerTitle, footerValue) {
        return {
            title: footerTitle,
            value: footerValue
        };
    }

    getTableElement(titleElem, tableRow, footerInfo, tableType, pagination) {
        const classData = this.classData;

        /**
         * (판매처 별 내역 / 앨범 별 내역 / 아티스트 별 내역 / 트랙 별 내역) 수익 상세 테이블의 경우
         * (bg-light, card 속성 제거) + (scroll-x 속성 추가)
         * **/
        if (tableType != null && tableType) {
            switch (tableType) {
                case 'SalesDetail':
                    classData["tableWrap"] = 'mt-1 h-100 px-2 py-2 text-center scroll-x';
                    break;
            }
        }

        return (<>
            <TableComponent
                ref={this.tableComponentRef}
                tableType={tableType}
                titleElem={titleElem}
                tableRow={tableRow}
                tableRowKeys={this.tableRowkeys}
                footerInfo={footerInfo}
                classData={classData}
                pagination={pagination}
            />
        </>);
    }

    /**
     * 테이블 동적 렌더링
     * TableComponent 메서드 직접 호출 및 테이블 데이터를 업데이트 작업 수행
     * @param tableHeaders
     * @param tableRows
     */
    replaceTableElement = (tableType, titleElem, tableKey, tableRow, footerInfo, pagination) => {
        if (this.tableComponentRef.current) {
            this.tableComponentRef.current.updateTable(tableType, titleElem, tableKey, tableRow, footerInfo, this.classData, pagination);
        }
    }

    /**
     * 테이블 내 Footer 정보를 동적으로 업데이트
     * TableComponent 메서드 직접 호출 및 테이블 내 Footer 데이터를 업데이트 작업 수행
     * @param updatedFooterInfo
     */
    updateFooterInfo = (updatedFooterInfo) => {
        if (this.tableComponentRef.current) {
            this.tableComponentRef.current.updateTableFooter(updatedFooterInfo);
        }
    }
}