import { ModelClass } from "../ModelClass";

export class CalcContract extends ModelClass{
  constructor(calcContract){
    super(calcContract);
    this.contractName = null;//계약명
    this.sumPrevRecoupmentBalance = 0;//전기잔액
    this.sumMoneySale = 0;//대상앨범 매출
    this.sumRecoupmentBalance = 0;//선급금액(당기 잔액)
    this.sumMoneyOffset = 0;//상계금액
    this.contractId = 0;
    this.init(calcContract);
  }

  init(calcContract){
    Object.keys(this).forEach(keyName=>{
      switch(keyName){
        case 'sumPrevRecoupmentBalance':
        case 'sumMoneySale':
        case 'sumRecoupmentBalance':
        case 'sumMoneyOffset':
          if(calcContract?.[keyName]){
            this[keyName] = this.getNumberWithDecimaPoint(calcContract[keyName],2);
          }
        break;

        default:
          if(calcContract?.[keyName]){
            this[keyName] = calcContract[keyName];
          }
        break;
      }
    })
  }

}