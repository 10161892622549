import React from 'react';
import { DataUtil } from '../../util/DataUtil';
import { CommonTable } from './CommonTable';

export class CommonShare extends React.Component{
  constructor(prop){
    super(prop);
    this.state = {
      revenueSum : 0,
      shareList : null,
      sum : 0
    };
    this.state.revenueSum= 0;
    this.state.shareList= null;
    this.state.sum= 0;
    this.dataUtil = new DataUtil();
    this.shareTitle = null;
    this.tableColsTitles = [];
    this.commTable = new CommonTable();
    this.commTable.tableRowkeys = [
      'name' , 
      'assetName',
      'itemName',
      'streaming',
      'download',
      'etc',
      'shareRate',
      'shareAmount'
    ]

  }

  getEleTop(){
    return(<>
      <div className='d-flex mt-4'>
        <div className="me-auto fw-bolder">{this.shareTitle}</div>
      </div>
    </>);
  }
  getElemTable(){
    const tableHeader = this.commTable.getTableHeads(this.tableColsTitles);
    const tableRow = this.commTable.getTableRows(this.state.shareList);
    const footerInfo = this.commTable.getFooterInfo('합계',this.dataUtil.currencyFormatter(this.state.sum));
    return(<>
      {this.commTable.getTableElement(tableHeader, tableRow,footerInfo)}
    </>);
  }
}