import React from 'react';
import { DataUtil } from '../../../util/DataUtil';
import '../../../css/common.css';
import '../../../css/componet/statements/settlmentInfo/PaymentInfo.css';
export class PaymentInfo extends React.Component{
  constructor(props){
    super(props);
    this.PAYMENT_STD = 99999999;
    this.dataUtil = new DataUtil(); 
  }

  comparePayment =(prepayment)=>{
    return `${ this.dataUtil.currencyFormatter(prepayment)}`
  }

  getFormatDate =(numberYYYYMMDD)=>{
    if(typeof numberYYYYMMDD == 'number'){
      const dateObject = this.dataUtil.covertNumbericDate(numberYYYYMMDD);
      const formattedDate = `${dateObject.getFullYear()}.${(dateObject.getMonth() + 1).toString().padStart(2, '0')}.${dateObject.getDate().toString().padStart(2, '0')}`;
      return formattedDate;
    }else{
      return numberYYYYMMDD;
    }
  }

  getFontSizeClass = (paymentValue) =>{
    let fontSizeClassName = 'fs-3';
    if(paymentValue > this.PAYMENT_STD){
      fontSizeClassName = 'fs-4';
    }

    return `col-sm-9 payment text-center ${fontSizeClassName}`;
  }

  getIncreasedRate(transferAmount, preTransferAmount){
    let rateValue = null;
    if(transferAmount === preTransferAmount ){
      rateValue = null;
    }else{
      if(transferAmount && preTransferAmount){
        rateValue = ((transferAmount - preTransferAmount)/preTransferAmount)*100;
      }else{
        rateValue = null;
      }
    }
    return (rateValue !== null)? `(${this.dataUtil.currencyFormatter(rateValue) }%)` : '';
  }

  getSettDate(userSalesProof) {
    // 정산일자 반환 (해당 분배월의 1일 고정)
    if (userSalesProof != null && userSalesProof) {
      if(userSalesProof.revenueMonth != null && userSalesProof.revenueMonth) {
        return (userSalesProof.revenueMonth * 100) + 1;
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  getDepositDate(userSalesProof) {
    if (userSalesProof != null && userSalesProof) {
      if (userSalesProof.revenueMonth != null && userSalesProof.revenueMonth) {
        return '말일 (영업일 기준)';
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  render(){
    const userSalesProof = this.props['salesProof'];
    const proofInfo   = {
      transferAmount :  (userSalesProof && userSalesProof?.transferAmount)? userSalesProof.transferAmount : 0,
      preTransferAmount : (userSalesProof && userSalesProof?.preTransferAmount)? userSalesProof.preTransferAmount : 0,
      settDate : this.getSettDate(userSalesProof),
      depositDate : this.getDepositDate(userSalesProof),
      bankName : (userSalesProof && userSalesProof.bankCode && userSalesProof.bankCode?.enumName)? userSalesProof.bankCode.enumName : '' ,
      accountNumber : (userSalesProof && userSalesProof?.bankAccountNum)? userSalesProof.bankAccountNum : ''
    }

    let rateSymbol = '';
    let classNameRate = 'text-secondary';
    const transferAmount = proofInfo.transferAmount;
    const preTransferAmount = proofInfo.preTransferAmount;
    const currentTransferAmount = transferAmount; // (vat, residentTax 더하는 코드 제거)

    if((currentTransferAmount + preTransferAmount) > 0 && currentTransferAmount === preTransferAmount){
      rateSymbol = '-';
    }else if(currentTransferAmount > preTransferAmount){
      rateSymbol = '▲'
      classNameRate = 'text-danger';
    }else if(currentTransferAmount < preTransferAmount){
      rateSymbol = '▼';
      classNameRate = 'text-info';
    }else{
      rateSymbol = '';
    }

    return(
      <div className='card h-100 mt-2 payment-bg drag-disable'>
        <div className='row p-2'>
          <span className='col-sm-7 d-flex flex-column px-4 border-end border-secondary'>
            <div>
              <strong>실 지급액</strong>
            </div>
            <div className='row'>
              <span className='col-sm-3 ps-4 pt-2 font-info-size fw-bolder'>KRW</span>
              <span className={this.getFontSizeClass(proofInfo.transferAmount)} title={proofInfo.transferAmount}>{this.dataUtil.currencyFormatter(proofInfo.transferAmount)}</span>
            </div>
            <div className='row'>
            <span className='col-sm-3 text-left font-info-size fw-bolder'>전월대비 증감</span>
              <span className='col-sm-9'>
                <div className="border border-dark rounded-pill text-center text-bg-light font-info-size bg-white">
                  <span className={classNameRate}>{rateSymbol}</span>
                  <span className='ms-1' title={transferAmount - preTransferAmount}>{this.comparePayment(transferAmount - preTransferAmount)}</span>
                  <span className='ms-1'>{this.getIncreasedRate(transferAmount,preTransferAmount)}</span>
                </div>
              </span>
            </div>
          </span>
          <span className='col-sm-5 d-flex flex-column'>
            {
                <span className='mt-3 font-info-size'>
                  <span className='fw-bolder'>정산일자:</span>
                  &emsp;
                  {this.dataUtil.getDotYYYYMMDD(proofInfo.settDate)}
                </span>
            }
            <span className='mt-2 font-info-size'>
                <span className='fw-bolder'>입금일자:</span>
              &emsp;
              {this.dataUtil.getDotYYYYMMDD(proofInfo.depositDate)}
            </span>
            <span className='mt-2 font-info-size row'>
              <span className='col-sm-3 fw-bolder'>입금계좌: </span>
              <span className='col-sm-9'>
                <div className="border border-dark rounded-pill text-center text-bg-light font-info-size  bg-white">
                  <span>{(proofInfo.bankName)? `${proofInfo.bankName} :` : '' }  </span>
                  <span>{this.dataUtil.maskingNumber(proofInfo.accountNumber)}</span>
                </div>
              </span>
            </span>
          </span>
        </div>
      </div>
    );
  }
}