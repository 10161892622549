import React from 'react';
import {Prepaid} from './Subtraction/Prepaid';
import {SubtractionShare} from './Subtraction/SubtractionShare';
import {SubtractionTotalSum} from './Subtraction/SubtractionTotalSum';
import {CarriedOverAmount} from "./Subtraction/CarriedOverAmount";

export class SubtractionInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    getElemTop() {
        return (<>
            <div className='row'>
                <div className='d-flex align-items-start'>
                    <div className='text-danger'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="currentColor"
                             className="bi bi-dash-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
                        </svg>
                    </div>
                    <div className='fw-bolder drag-disable'>&nbsp;차감&nbsp;</div>
                    <div className='ms-1'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="currentColor"
                             className="bi bi-question-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                        </svg>
                    </div>
                </div>
            </div>
        </>)
    }

    getTotalSum() {
        const calculateContract = this.props.calculateContract;
        const takeCalculateRevenue = this.props.takeCalculateRevenue;
        const carryOver = this.props.carryOver;
        let totalSum = 0;
        let sumMoneyOffsetTotal = 0;
        let sumRevenueMoneyTotal = 0;
        let carriedOverAmountTotal = 0;

        // 선급 차감
        if (calculateContract && calculateContract?.length) {
            sumMoneyOffsetTotal = calculateContract.reduce((accumulator, currentItem) => {
                return accumulator + currentItem.sumMoneyOffset;
            }, 0);
        }

        // 수익 분배 차감
        if (takeCalculateRevenue && takeCalculateRevenue?.length) {
            sumRevenueMoneyTotal = takeCalculateRevenue.reduce((accumulator, currentItem) => {
                return accumulator + currentItem.sumRevenueMoney;
            }, 0);
        }

        // 차기 이월 차감
        if (carryOver && carryOver?.length) {
            const carriedOverAmount = carryOver[carryOver.length - 1]["carriedOverAmount"];
            carriedOverAmountTotal += (carriedOverAmount != null && carriedOverAmount ? carriedOverAmount : 0);
        }

        totalSum = sumMoneyOffsetTotal + sumRevenueMoneyTotal + carriedOverAmountTotal;
        return totalSum;
    }

    render() {
        return (
            <div className='row mt-4'>
                <div className='card p-3 innerCard '>
                    {this.getElemTop()}
                    {
                        (this.props.calculateContract?.length) ?
                            <Prepaid
                                calculateContract={this.props.calculateContract}
                                salesProof={this.props?.salesProof}
                            /> : <></>
                    }
                    <SubtractionShare
                        takeCalculateRevenue={this.props.takeCalculateRevenue}
                    />
                    <CarriedOverAmount
                        carryOver={this.props.carryOver}
                    />
                    <SubtractionTotalSum
                        totalSum={this.getTotalSum()}
                    />
                </div>
            </div>
        );
    }
}