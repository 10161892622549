import { ModelClass } from "./ModelClass";
import { CalcContract } from "./SalseRevenue/CalcContract";
import { CalcRevenue } from "./SalseRevenue/CalcRevenue";
import { CalcRightInfo } from "./SalseRevenue/CalcRightInfo";
import { CalcTotalSale } from "./SalseRevenue/CalcTotalSale";
import {CalcCarryOver} from "./SalseRevenue/CalcCarryOver";
import {Adjustment} from "./Adjustment";

export class SalesRevenue extends ModelClass{
  constructor(salesRevenue){
    super(salesRevenue);
    //this.calculateRightInfo = null;
    this.calculateTotalSale = null;
    this.getCalculateRevenue = null;
    this.calculateContract = null;
    this.takeCalculateRevenue = null;
    this.paymentList = null;
    this.offsetList = null;
    this.revenueList = null;
    this.carryOverList = null;
    this.adjustmentList = null;
    this.init(salesRevenue);
  }

  init(salesRevenue) {
    Object.keys(this).forEach(keyName => {
      switch (keyName) {
        case 'calculateRightInfo':
          this[keyName] = new CalcRightInfo(salesRevenue?.[keyName]);
          break;

        case 'calculateTotalSale':// 권리자 정산 명세서 카탈로그별 수익 정보
          if (salesRevenue?.[keyName] && salesRevenue[keyName]?.length) {
            const calcTotalSaleList = [];
            salesRevenue[keyName].forEach(calcTotalSale => {
              calcTotalSaleList.push(new CalcTotalSale(calcTotalSale));
            });
            this[keyName] = calcTotalSaleList;
          }
          break;

        case 'getCalculateRevenue'://09 받은 분배 내역        
        case 'takeCalculateRevenue'://11 수익 분배 차감
          if (salesRevenue?.[keyName] && salesRevenue[keyName]?.length) {
            const getCalculateRevenueList = [];
            salesRevenue[keyName].forEach(calcRevenue => {
              getCalculateRevenueList.push(new CalcRevenue(calcRevenue));
            })
            this[keyName] = getCalculateRevenueList;
          }
          break;

        case 'calculateContract'://10 선급금 상계
          if (salesRevenue?.[keyName] && salesRevenue[keyName]?.length) {
            const calculateContract = [];
            salesRevenue[keyName].forEach(calcContract => {
              calculateContract.push(new CalcContract(calcContract));
            })
            this[keyName] = calculateContract;
          }
          break;

        case 'carryOverList': // 이월 내역
          if (salesRevenue?.[keyName] && salesRevenue[keyName]?.length) {
            const carryOverList = [];
            salesRevenue[keyName].forEach(carryOver => {
              carryOverList.push(new CalcCarryOver(carryOver));
            })
            this[keyName] = carryOverList;
          }
          break;

        case 'adjustmentList': // 조정 내역
            if(salesRevenue?.[keyName] && salesRevenue[keyName]?.length) {
                const adjustmentList = [];
                salesRevenue[keyName].forEach(adjustment => {
                  adjustmentList.push(new Adjustment(adjustment));
                })
                this[keyName] = adjustmentList;
            }
          break;

        case 'paymentList'://B-1 지급리스트
          break;

        case 'offsetList'://B-2 상계리스트
          break;

        case 'revenueList'://판매처 별 리스트
          break;

        default:
          break;
      }
    });
  }
}