import {ModelClass} from "../../ModelClass";

/**
 * 트랙 별 내역
 */
export class TrackRevenueItem extends ModelClass {
    constructor(revenueItem) {
        super(revenueItem);
        this.saleStartDate = 0; //발매일
        this.distributor = 0;//발매사
        this.assetCode = null; // 앨범 코드
        this.itemCode = null; // 트랙 코드
        this.albumName = null; // 앨범 명
        this.trackName = null; // 트랙 명
        this.artist = null; // 아티스트
        this.cntStream = null;
        this.cntDownload = null;
        this.cntEtc = null;
        this.sumRevenueMoney = 0; // 판매액 (매출)
        this.sumRevenueFee = 0; // 수수료
        this.sumRevenueTotal = 0; // 수익 (수수료 제외)
        this.init(revenueItem);
    }

    init(revenueItem) {
        Object.keys(this).forEach(keyName => {
            switch (keyName) {
                case 'revenueMonth':
                case 'calculateMonth':
                case 'serviceMonth':
                    if (revenueItem?.[keyName]) {
                        this[keyName] = this.getYearMonthStr(revenueItem[keyName], this.fmt_yyyyMM_dot);
                    }
                    break;

                case 'saleStartDate':
                    this[keyName] = this.getDateToStr(revenueItem[keyName]);
                    break;

                case 'cntStream':
                case 'cntDownload':
                case 'cntEtc':
                case 'sumRevenueMoney':
                case 'sumRevenueFee':
                case 'sumRevenueTotal':
                    this[keyName] = this.getNumberWithDecimaPoint(revenueItem?.[keyName], 2);
                    break;

                default:
                    if (revenueItem?.[keyName]) {
                        this[keyName] = revenueItem[keyName];
                    }
                    break;
            }
        });
    }
}