import React from 'react';
import {Modal} from "../common/Modal";
import {PaginationUtil} from "../../util/PaginationUtil";
import {APIConfig} from "../../constans/APIConfig";
import AxiosClient from "../../util/AxiosClient";
import {CommonTable} from "../common/CommonTable";
import {NoticeItem} from "../../model/notice/NoticeItem";
import {ReactComponent as IconSearch} from '../../media/icon/search.svg';

/**
 * 공지사항 목록(리스트) 컴포넌트
 */
export class NoticeListComponent extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            // TODO
        }
        this.swal = new Modal();

        this.paginationUtil = new PaginationUtil();
        this.commTable = new CommonTable();

        this.apiConfig = new APIConfig();
        this.apiCaller = new AxiosClient(this.apiConfig.API_URL);

        this.commTable.classData.table = 'table table-light';
        this.commTable.classData.tableHead = 'table-normal-gray';
        this.commTable.classData.tableBody = 'table-light ft-size-rem-08';

        this.HTTP_STATUS_CODE = this.apiConfig.HTTP_STATUS_CODE;

        this.tableHeadTitles = ['번호', '유형', '제목', '등록일'];
        this.commTable.tableRowkeys = ['noticeId', 'noticeType', 'title', 'regDate'];
    }

    // 공지사항 페이지 타입 변경을 상위 컴포넌트로 전달
    switchToNoticePageType = (pageType, noticeId) => {
        this.props.onPageTypeChange(pageType, noticeId);
    };

    getIconElement(iconTitle, ReactComponent) {
        return (
            <span className='icon header-icon border-radius-md'>
          <ReactComponent/>
        </span>
        );
    }

    /**
     * 페이지네이션 이벤트 메서드
     */
    getPagination(self, paginationObj) {
        // 리스트 수 UPDATE
        if (document.getElementById("noticeTotalCount") != null && document.getElementById("noticeTotalCount")) {
            document.getElementById("noticeTotalCount").innerText = (paginationObj != null && paginationObj) ? paginationObj["totalItems"] : 0;
        }

        return {
            totalItems: (paginationObj != null && paginationObj) ? paginationObj["totalItems"] : null,
            itemCountPerPage: (paginationObj != null && paginationObj) ? paginationObj["itemCountPerPage"] : null,
            pageCount: (paginationObj != null && paginationObj) ? paginationObj["pageCount"] : null,
            currentPage: (paginationObj != null && paginationObj) ? paginationObj["currentPage"] : null,
            onPageChange: (pageNumber) => {
                // 공지사항 API 호출 및 데이터 처리
                const noticeSelectCategory = (document.getElementById("noticeSelectCategory") === null || !document.getElementById("noticeSelectCategory")) ? 'title' : document.getElementById("noticeSelectCategory").value;
                const noticeSearchInput = (document.getElementById("noticeTableSearchInput") === null || !document.getElementById("noticeTableSearchInput")) ? '' : document.getElementById("noticeTableSearchInput").value;
                const queryStr = `${this.paginationUtil.getPaginationReq(pageNumber, document.getElementById("noticePageSizeSelect"))}&searchCategory=${noticeSelectCategory}&searchWord=${noticeSearchInput === null || !noticeSearchInput ? '' : noticeSearchInput}`;
                const apiPath = `${this.apiConfig.NOTICE_LIST.getPath(this.props.rightsHolders.rightId, queryStr)}`;

                // 공지사항 API 호출 및 데이터 처리
                this.apiCaller.getRequest(apiPath, (res) => {
                    // 세션 만료 (401) 발생 시에 로그인 요청
                    if (res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                        this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                        return;
                    }

                    if (res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                        this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                        return;
                    }

                    // 200 응답일 때에만 테이블 렌더링
                    if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                        const responseBody = res.data?.body;
                        const responseNoticeList = [];

                        // 최상단 공지사항 내역
                        if (responseBody?.['noticeTopLists'] && responseBody['noticeTopLists']?.length) {
                            responseBody['noticeTopLists'].forEach(notice => {
                                responseNoticeList.push(new NoticeItem(notice));
                            });
                        }

                        if (responseBody?.['noticeLists'] && responseBody['noticeLists']?.length) {
                            responseBody['noticeLists'].forEach(notice => {
                                responseNoticeList.push(new NoticeItem(notice));
                            });
                        }

                        // 공지사항 링크 클릭 시에, 상세 페이지 이동 콜백 등록
                        responseNoticeList.forEach(responseNotice => {
                            responseNotice.setCallBack((noticeId) => {
                                this.switchToNoticePageType('noticeDetail', noticeId);
                            });
                        });
                        this.commTable.replaceTableElement('noticeList', this.tableHeadTitles, this.commTable.tableRowkeys, responseNoticeList, null, this.getPagination(this, responseBody['paginationResponse']));
                    }
                });
            }
        }
    }

    /**
     * 공지사항 정보 요청
     */
    reqNoticeInfo() {
        try {
            // 공지사항 API 호출 및 데이터 처리
            const noticeSelectCategory = (document.getElementById("noticeSelectCategory") === null || !document.getElementById("noticeSelectCategory")) ? 'title' : document.getElementById("noticeSelectCategory").value;
            const noticeSearchInput = (document.getElementById("noticeTableSearchInput") === null || !document.getElementById("noticeTableSearchInput")) ? '' : document.getElementById("noticeTableSearchInput").value;
            const queryStr = `${this.paginationUtil.getPaginationReq(null, document.getElementById("noticePageSizeSelect"))}&searchCategory=${noticeSelectCategory}&searchWord=${noticeSearchInput === null || !noticeSearchInput ? '' : noticeSearchInput}`;
            const apiPath = `${this.apiConfig.NOTICE_LIST.getPath(this.props.rightsHolders.rightId, queryStr)}`;

            this.apiCaller.getRequest(apiPath, (res) => {
                // 세션 만료 (401) 발생 시에 로그인 요청
                if (res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                    this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                    return;
                }

                if (res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                    this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                    return;
                }

                // 200 응답일 때에만 테이블 렌더링
                if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                    const responseBody = res.data?.body;
                    const responseNoticeList = [];

                    // 최상단 공지사항 내역
                    if (responseBody?.['noticeTopLists'] && responseBody['noticeTopLists']?.length) {
                        responseBody['noticeTopLists'].forEach(notice => {
                            responseNoticeList.push(new NoticeItem(notice));
                        });
                    }

                    if (responseBody?.['noticeLists'] && responseBody['noticeLists']?.length) {
                        responseBody['noticeLists'].forEach(notice => {
                            responseNoticeList.push(new NoticeItem(notice));
                        });
                    }

                    // 공지사항 링크 클릭 시에, 상세 페이지 이동 콜백 등록
                    responseNoticeList.forEach(responseNotice => {
                        responseNotice.setCallBack((noticeId) => {
                            this.switchToNoticePageType('noticeDetail', noticeId);
                        });
                    });
                    this.commTable.replaceTableElement('noticeList', this.tableHeadTitles, this.commTable.tableRowkeys, responseNoticeList, null, this.getPagination(this, responseBody['paginationResponse']));
                }
            });
        } catch (error) {
            this.swal.alert('공지사항 정보를 불러오는 중에 에러가 발생했습니다. ERROR:' + error);
        }
    }

    /**
     * 공지사항 테이블 리렌더링
     * **/
    reRenderNoticeList() {
        // 공지사항 API 호출 및 데이터 처리
        const noticeSelectCategory = (document.getElementById("noticeSelectCategory") === null || !document.getElementById("noticeSelectCategory")) ? 'title' : document.getElementById("noticeSelectCategory").value;
        const noticeSearchInput = (document.getElementById("noticeTableSearchInput") === null || !document.getElementById("noticeTableSearchInput")) ? '' : document.getElementById("noticeTableSearchInput").value;
        const queryStr = `${this.paginationUtil.getPaginationReq(null, document.getElementById("noticePageSizeSelect"))}&searchCategory=${noticeSelectCategory}&searchWord=${noticeSearchInput === null || !noticeSearchInput ? '' : noticeSearchInput}`;
        const apiPath = `${this.apiConfig.NOTICE_LIST.getPath(this.props.rightsHolders.rightId, queryStr)}`;

        // 공지사항 API 호출 및 데이터 처리
        this.apiCaller.getRequest(apiPath, (res) => {
            // 세션 만료 (401) 발생 시에 로그인 요청
            if (res.status == this.HTTP_STATUS_CODE.CLIENT_401_UNAUTHORIZED) {
                this.swal.alert(`[상태코드:${res.status}] 세션이 만료되었습니다. 페이지를 새로고침 해주세요.`);
                return;
            }

            if (res.status >= this.HTTP_STATUS_CODE.SERVER_500_INTERNAL_ERROR) {
                this.swal.alert(`[상태코드:${res.status}] 데이터 조회 중에 오류가 발생하였습니다.`);
                return;
            }

            // 200 응답일 때에만 테이블 렌더링
            if (res.status === this.HTTP_STATUS_CODE.SUCCESS_200_OK && res?.data && res.data?.body) {
                const responseBody = res.data?.body;
                const responseNoticeList = [];

                // 최상단 공지사항 내역
                if (responseBody?.['noticeTopLists'] && responseBody['noticeTopLists']?.length) {
                    responseBody['noticeTopLists'].forEach(notice => {
                        responseNoticeList.push(new NoticeItem(notice));
                    });
                }

                if (responseBody?.['noticeLists'] && responseBody['noticeLists']?.length) {
                    responseBody['noticeLists'].forEach(notice => {
                        responseNoticeList.push(new NoticeItem(notice));
                    });
                }

                // 공지사항 링크 클릭 시에, 상세 페이지 이동 콜백 등록
                responseNoticeList.forEach(responseNotice => {
                    responseNotice.setCallBack((noticeId) => {
                        this.switchToNoticePageType('noticeDetail', noticeId);
                    });
                });
                this.commTable.replaceTableElement('noticeList', this.tableHeadTitles, this.commTable.tableRowkeys, responseNoticeList, null, this.getPagination(this, responseBody['paginationResponse']));
            }
        });
    }

    render() {
        const noticeList = this.reqNoticeInfo();
        const tableHeaders = this.commTable.getTableHeads(['번호', '유형', '제목', '작성일']);
        const tableRow = this.commTable.getTableRows([], 'table-bordered');
        return (<>
            <div className='row mt-4'>
                <div className='card p-3 innerCard'>
                    <div className="px-2 clear_fix">
                        <span style={{float: 'left'}}>리스트 수 : <span
                            id="noticeTotalCount">{0}</span></span>

                        <div style={{float: 'right'}}>
                            <div className="clear_fix">
                                <div id="noticeSelectCategoryWrap" className="ml-md-auto"
                                     style={{float: 'left', paddingRight: '1rem'}}>
                                    <div className="input-group-sm">
                                        <select id="noticeSelectCategory" className=""
                                                style={{border: '1px solid #495057', color: '#495057'}}>
                                            <option value="title">제목</option>
                                            <option value="content">내용</option>
                                        </select>
                                    </div>
                                </div>

                                <div id="noticeTableSearchWrap" className="ml-md-auto"
                                     style={{float: 'left', paddingRight: '1rem'}}>
                                    <div className="input-group-sm" style={{position: 'relative'}}>
                                        <input type="text" id="noticeTableSearchInput" className=""
                                               placeholder="검색어를 입력해주세요." title="검색어를 입력해주세요."
                                               onKeyPress={(e) => {
                                                   if (e.key === 'Enter') {
                                                       this.reRenderNoticeList();
                                                   }
                                               }}
                                               style={{height: '1.6rem'}}/>
                                        <span className="ms-cursor-over" title="공지사항 검색" onClick={(e) => {
                                            this.reRenderNoticeList();
                                        }}>
                                            {this.getIconElement('', IconSearch)}
                                        </span>
                                    </div>
                                </div>

                                <div id="pageSizeSelectWrap" className="ml-md-auto" style={{float: 'right'}}>
                                    <div className="input-group-sm">
                                        <select id="noticePageSizeSelect" className=""
                                                style={{border: '1px solid #495057', color: '#495057'}}
                                                onChange={(e) => {
                                                    this.reRenderNoticeList();
                                                }}>
                                            <option value="10" selected={true}>10개 보기</option>
                                            <option value="20">20개 보기</option>
                                            <option value="30">30개 보기</option>
                                            <option value="50">50개 보기</option>
                                            <option value="100">100개 보기</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.commTable.getTableElement(tableHeaders, tableRow, null, 'SalesDetail', null)}
                </div>
            </div>
        </>);
    }
}
