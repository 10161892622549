import React from 'react';
import '../../../css/common.css';
import '../../../css/componet/statements/settlmentInfo/TransactionProof.css';
import { DataUtil } from '../../../util/DataUtil';

export class TransactionProof extends React.Component{
  constructor(props){
    super(props);
    this.dataUtil = new DataUtil()
  }

  convertCurrecy(pramAmount){
    return this.dataUtil.currencyFormatter(pramAmount)
  }

    getNotBrandedFormatedStrDate(revenueMonth, intgDay) {
    let issuedDate = '';

    if (revenueMonth) {
      const numYear = Math.floor(revenueMonth / 100);
      const numMonth = revenueMonth % 100;

      const strYear = `${numYear}년`;
      const strMonth = `${(numMonth >= 10) ? numMonth : '0' + numMonth}월`;
      const strDay = `${(intgDay > 0) ? (intgDay => 10) ? intgDay : '0' + intgDay : ''}`;

      return `${strYear} ${strMonth} ${(strDay.length > 0) ? strDay + '일' : ''}`;
    }
    return issuedDate;
  }

  getFormateStrDate(rightHolder, revenueMonth, intgDay) {
    let issuedDate = '';

    if (revenueMonth) {
      const isYejeonMedia = (rightHolder != null && rightHolder) ? this.dataUtil.checkVostokYejeonMedia(rightHolder.brandCode.code, rightHolder.rightId) : false;

      let numYear = Math.floor(revenueMonth / 100);
      let numMonth = revenueMonth % 100;

      if (isYejeonMedia === false) {
        // 예전미디어가 아닌 경우, 분배월 + 1M 을 한다.
        if ((numMonth + 1) > 12) {
          numMonth = 1;
          numYear += 1;
        } else {
          numMonth += 1;
        }
      }

      const strYear = `${numYear}년`;
      const strMonth = `${(numMonth >= 10) ? numMonth : '0' + numMonth}월`;
      const strDay = `${(intgDay > 0) ? (intgDay => 10) ? intgDay : '0' + intgDay : ''}`;

      return `${strYear} ${strMonth} ${(strDay.length > 0) ? strDay + '일' : ''}`;
    }
    return issuedDate;
  }

  getClosingDate(rightHolder){
    const brandInfo = rightHolder?.brandCode
    let closingDay = null;
    if(brandInfo && brandInfo?.code){
      if(brandInfo.code === 'ZZ'){//오즈
        closingDay = 16;
      }
      if(brandInfo.code === 'VT'){//보스토크
        closingDay = 25;
      }
      if(brandInfo.code === 'PO'){//포크라노스
        closingDay = 25;
      }
    }
    return closingDay;
  }

  getDueDate(rightHolder, settYearMonth) {
    const brandInfo = rightHolder?.brandCode
    let dueDateString = null;

    if (brandInfo && brandInfo?.code) {
      /*
        if(brandInfo.code === 'ZZ'){//오즈
          dueDateString = `${settYearMonth} 16일 마감`;
        }
        if(brandInfo.code === 'VT'){//보스토크
          dueDateString = `당월 25일 마감`;
        }
        if(brandInfo.code === 'PO'){//포크라노스
          dueDateString = `${settYearMonth} 25일 마감`;
        }
      */
      dueDateString = `${settYearMonth} 25일 마감`;
    }
    return dueDateString;
  }

  getWrittenDate(rightHolder , settYearMonth){
    const brandInfo = rightHolder?.brandCode

    let writtenDateString = null;

    if(brandInfo && brandInfo?.code){
      /*
        if(brandInfo.code === 'ZZ'){//오즈
          writtenDateString = `${settYearMonth} 10일`;
        }
        if(brandInfo.code === 'VT'){//보스토크
          writtenDateString = `정산 공지 메일 참조`;
        }
        if(brandInfo.code === 'PO'){//포크라노스
          writtenDateString = `${settYearMonth} 10일`;
        }
      */
      writtenDateString = `${settYearMonth} 10일`;
    }
    return writtenDateString;
  }


  getProductTitle(rightHolder , settYearMonth){
    const brandInfo = rightHolder?.brandCode;
    let titleString = null;
    if(brandInfo && brandInfo?.code){
      if(brandInfo.code === 'ZZ'){//오즈
        titleString = `오즈 ${settYearMonth} 정산금 (품목에 '오즈' 포함 必)`;
      }
      if(brandInfo.code === 'VT'){//보스토크
        titleString = `보스토크 ${settYearMonth} 정산금 (품목에 '보스토크' 포함 必)`;
      }
      if(brandInfo.code === 'PO'){//포크라노스
        titleString = `포크라노스 ${settYearMonth} 정산금 (품목에 '포크라노스' 포함 必)`;
      }
    }
    return titleString;
  }

  render(){
    /**
     * 권리자가 사업자(계산서) 유형일 때만 노출
     */
    const salesProof = this.props.salesProof;
    const royaltyType = (salesProof && salesProof?.royaltyType)? salesProof?.royaltyType : null;
    if(royaltyType && (royaltyType.code === 'II' || royaltyType.code === 'CR') ){
      const stateTransferAmount = salesProof.paymentAmount;
      const stateTax = salesProof.vat + salesProof.residentTax;
      const stateSum = stateTransferAmount + stateTax;
      this.state ={
        businessLicenseNum : salesProof.businessLicenseNum,
        companyName : salesProof.companyName,
        taxInvoiceIssuanceDate : this.getWrittenDate(this.props?.rightHolder, this.getFormateStrDate(this.props.rightHolder, this.props.revenueMonth)),
        transferAmount : stateTransferAmount,
        tax : stateTax,
        sum : stateSum
      }
      return(
        <>
          <div>
            <span className='fw-bold drag-disable'>거래증빙 정보</span>
            <span className='ms-2 cursorPointer'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
              </svg>
            </span>
          </div>

          <div className="d-flex" id='tradeMarkInfo'>
            <div className='width-50-per mt-2 mb-3 px-3'>
              <div className="col-sm-20 card h-100 mt-1 px-1 py-1 bg-light text-center">
                <div
                    className='text-end'>*{this.getDueDate(this.props?.rightHolder, this.getFormateStrDate(this.props.rightHolder, this.props.revenueMonth))}</div>
                <table>
                  <tbody className='text-start'>
                  <tr className='border-top border-black'>
                    <td className='border-end border-black'>작성일</td>
                    <td>{this.state.taxInvoiceIssuanceDate}</td>
                  </tr>
                  <tr className='border-top border-black'>
                    <td className='border-end border-black'>품목명</td>
                    <td>{this.getProductTitle(this.props?.rightHolder, this.getNotBrandedFormatedStrDate(this.props.revenueMonth))}</td>
                  </tr>
                  <tr className='border-top border-black'>
                    <td className='border-end border-black'>금액</td>
                    <td>공급가액과 부가세 란의 금액 참조</td>
                  </tr>
                  <tr className='border-top border-bottom border-black'>
                    <td className='border-end border-black'>메일주소</td>
                    <td>{this.props?.rightHolder.taxBillingEmail}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-sm-8"></div>
            </div>

            <div className='width-50-per mt-2 mb-3 px-3'>
              <div className='col-sm-20 card h-100 mt-1 px-1 py-1 bg-light'>
                <div style={{paddingTop: '0.5rem', paddingLeft: '0.5rem', paddingRight: '1rem', fontWeight: 500}}>
                  <span>(세금)계산서 발행 주의사항</span>

                  <ul style={{marginTop: '0.5rem'}}>
                    <li>
                      <span>모든 증빙은 반드시 {this.getFormateStrDate(this.props.rightHolder, this.props.revenueMonth, 25)} 까지 발행을 부탁드립니다.</span>
                    </li>

                    <li style={{marginTop: '0.5rem'}}>
                      <span>마감일 이후 발행된 증빙은 회계 처리가 불가하여 이월됩니다.</span>
                    </li>

                    <li style={{marginTop: '0.5rem'}}>
                      <span>이월 정산금이 있는 경우 반드시 당월분과 합산 발행 부탁드립니다. (하단 공급가액/부가세/합계금액 란의 금액 참조)</span>
                    </li>

                    <li style={{marginTop: '0.5rem'}}>
                      <span>작성일자는 반드시 {this.getFormateStrDate(this.props.rightHolder, this.props.revenueMonth, 10)}로 발행 부탁드립니다. (과거 일자로 발행 시 처리 불가)</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className='card h-100 mt-2 px-4 py-3 bg-light text-center'>
              <table>
                <thead>
                <tr className='border-bottom border-black'>
                  <th>사업자번호</th>
                  <th>사업자명</th>
                  <th>작성일</th>
                  <th>공급가액</th>
                  <th>부가세</th>
                  <th>합계금액</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{this.state.businessLicenseNum}</td>
                  <td>{this.state.companyName}</td>
                  <td>{this.state.taxInvoiceIssuanceDate}</td>
                  <td title={this.state.transferAmount}>{this.convertCurrecy(this.state.transferAmount)}</td>
                  <td title={this.state.tax}>{this.convertCurrecy(this.state.tax)}</td>
                  <td title={this.state.sum}>{this.convertCurrecy(this.state.sum)}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    } else {
      return (<></>);
    }
  }
}