import React from 'react';
import {CommonTable} from "../../../common/CommonTable";
import {Modal} from '../../../common/Modal';
import {CarryOver} from '../../../../model/CarryOver';
import {TableUtil} from '../../../../util/TableUtil';

export class CarriedOverAmount extends React.Component {
    constructor(prop) {
        super(prop);
        this.commTable = new CommonTable();
        this.tableUtil = null;
        this.swal = null;
        this.tableHeadTitles = [
            '이월 사유',
            '차기이월'
        ];
        this.commTable.tableRowkeys = [
            'carryOverMemo',
            'carriedOverAmount'
        ];
    }

    getEleTop() {
        const boaderClass = `border rounded-pill border-dark `;
        const msEventClass = `ms-cursor-over `;
        const textAndEtc = 'text-center px-4 bg-light '
        return (<>
            <div className='d-flex mt-4'>
                <div className="me-auto fw-bolder">차기이월 내역</div>
            </div>
        </>);
    }

    getCarriedOverAmountSummary(carryOverList) {
        const carriedOverAmountSummary = {
            carryOverMemo: '-', /* 추후에, 이월/마감/조정 처리 기능 개발 이후에, 필드 값 매칭 */
            carriedOverAmount: 0
        };

        if (carryOverList != null && carryOverList?.length) {
            const lastCarryOver = carryOverList[carryOverList.length - 1]["carriedOverAmount"];
            const carriedOverAmount = (lastCarryOver != null && lastCarryOver) ? lastCarryOver : 0;
            carriedOverAmountSummary["carriedOverAmount"] += carriedOverAmount;
        }
        return carriedOverAmountSummary;
    }

    render() {
        if (this.props && this.props.carryOver?.length > 0) {
            const tableHeaders = this.commTable.getTableHeads(this.tableHeadTitles);
            const tableRow = this.commTable.getTableRows([this.getCarriedOverAmountSummary(this.props.carryOver)]);
            return (<>
                {this.getEleTop()}
                {this.commTable.getTableElement(tableHeaders, tableRow)}
            </>);
        } else {
            return (<></>);
        }
    }
}