
import React from 'react';
import { DataUtil } from '../../util/DataUtil';

export class CommonTotalSum extends React.Component{
  constructor(prop){
    super(prop);
    this.dataUtil = new DataUtil();
    this.totalSumTitle = null;
    this.sumFontClassName = null;
  }

  getTotalSumElem(){
    return (<>
    <div className='card h-100 px-4 bg-light text-center mt-3'>
      <div className='d-flex'>
        <div className='me-auto fw-bolder p-3'>{this.totalSumTitle}</div>
        <div className='me-3 p-3'>KRW</div>
        <div className='fs-3 p-1 fw-bolder'>
          <span className={this.sumFontClassName} title={this.props.totalSum}>{this.dataUtil.currencyFormatter(this.props.totalSum)}</span>
        </div>
      </div>
    </div>
    </>);
  }
}