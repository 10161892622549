import React from 'react';
import '../../css/componet/pagination/pagination.css';
import {PaginationUtil} from "../../util/PaginationUtil";

/**
 * Pagination Component
 */
export default class CommonPagination extends React.Component {
    constructor(props) {
        super(props);
        this.paginationUtil = new PaginationUtil();
    }

    render() {
        const totalItems = (this.props.totalItems == null || !this.props.totalItems) ? 0 : this.props.totalItems; // 전체 아이템 갯수
        const itemCountPerPage = (this.props.itemCountPerPage == null || !this.props.itemCountPerPage) ? this.paginationUtil.pageLimit : this.props.itemCountPerPage; // 페이지 당 아이템 갯수
        const pageCount = (this.props.pageCount == null || !this.props.pageCount) ? this.paginationUtil.pageCount : this.props.pageCount; // 이전 ... 다음 사이에 선택 가능 페이지 갯수
        const currentPage = (this.props.currentPage == null || !this.props.currentPage) ? 1 : this.props.currentPage;  // 현재 선택 페이지 위치
        const onPageChange = (this.props.onPageChange == null || !this.props.onPageChange) ? null : this.props.onPageChange; // 페이지 클릭 콜백 메서드
        const totalPages = Math.ceil(totalItems / itemCountPerPage); // 전체 페이지 갯수
        const pageNumbers = [];
        const startPage = currentPage - Math.floor(pageCount / 2);
        let endPage = currentPage + Math.floor(pageCount / 2);

        if (startPage <= 1) {
            endPage = pageCount;
        }
        if (endPage > totalPages) {
            endPage = totalPages;
        }

        for (let i = startPage; i <= endPage; i++) {
            if (i > 0) {
                pageNumbers.push(i);
            }
        }

        const renderPageNumbers = pageNumbers.map(number => (
            <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                <a className="page-link" onClick={() => currentPage != number && onPageChange(number)}>
                    {number}
                </a>
            </li>
        ));

        return (
            <nav className="pagination-nav">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <a className="page-link"
                           onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}>이전</a>
                    </li>
                    {renderPageNumbers}
                    <li className={`page-item ${currentPage >= totalPages ? 'disabled' : ''}`}>
                        <a className="page-link"
                           onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}>다음</a>
                    </li>
                </ul>
            </nav>
        );
    }
}
