import React from 'react';
import {NoticeHeader} from "./NoticeHeader";
import {NoticeListComponent} from "./NoticeListComponent";
import {NoticeDetailComponent} from "./NoticeDetailComponent";
import '../../css/componet/notice/notice.css';
import {UrlParserUtil} from "../../util/UrlParserUtil";

/**
 * 공지사항 컴포넌트
 */
export class NoticeComponent extends React.Component {
    constructor(prop) {
        super(prop);
        this.urlParserUtil = new UrlParserUtil();

        /**
         * pageType : noticeList || noticeDetail
         * noticeId : null || 공지사항 ID
         *
         * sideBarMenuType 값이 null인 경우는 default = 공지사항 목록 (noticeList) 이동
         *
         * sideBarMenuType 값이 null 이 아닌 경우
         * sidebarMenuType: noticeDetail/{noticeId}
         * (ex: 팝업 창 에서 공지사항 바로가기) 에는 파싱 처리 및 지정
         * @type {string|*}
         */
        const sidebarMenuType = this.props.sideBarMenuType;

        if (sidebarMenuType != null && sidebarMenuType && sidebarMenuType.startsWith('noticeDetail')) {
            const parsed = sidebarMenuType.split('/');
            if (parsed != null && parsed && parsed.length > 1) {
                this.state = {
                    pageType: parsed[0],
                    noticeId: parsed[1]
                };
            } else {
                this.state = {
                    pageType: 'noticeList', // 기본 값 (공지사항 목록)
                    noticeId: null
                }
            }
        } else {
            if (sidebarMenuType != null && sidebarMenuType && sidebarMenuType.startsWith('notice')) { // 공지사항 사이드바 메뉴 클릭 시에
                this.state = {
                    pageType: 'noticeList', // 기본 값 (공지사항 목록)
                    noticeId: null
                }
            } else {
                /**
                 * 사이드바 메뉴 클릭 | 공지사항 링크를 클릭하지 않고, 브라우저 URL 입력 만으로 접속한 경우 처리
                 * 아래와 같이 URL 내 QueryParam 파싱 결과에 따라 state 지정

                 CASE 1) ?notice/list

                 this.setState({
                 pageType: noticeList, // 공지사항 목록
                 noticeId: null
                 });

                 CASE 2) ?notice/view/{noticeId}

                 this.setState({
                 pageType: noticeDetail, // 공지사항 상세
                 noticeId: noticeId // queryParam 내 Number
                 });
                 * **/
                const parsedQueryParam = this.urlParserUtil.getParsedQueryParam('/', '=');
                console.log("queryParam:{}", parsedQueryParam);

                if (parsedQueryParam != null && parsedQueryParam && parsedQueryParam.length > 1) {
                    const isNotice = (parsedQueryParam[0] === 'notice');
                    const menu = parsedQueryParam[1];

                    if (isNotice === true) {
                        if (menu != null && menu) {
                            switch (menu) {
                                case 'list': // 공지사항 목록 페이지 이동
                                    this.state = {
                                        pageType: 'noticeList', // 기본 값 (공지사항 목록)
                                        noticeId: null
                                    }
                                    break;

                                case 'view': // 공지사항 상세 페이지 이동
                                    if (parsedQueryParam.length > 2) {
                                        const noticeId = parsedQueryParam[2];

                                        if (noticeId != null) {
                                            this.state = {
                                                pageType: 'noticeDetail',
                                                noticeId: noticeId
                                            };
                                            break;
                                        }
                                    }

                                    // 잘못된 URL | noticeId 가 잘못 된 경우, 기본(공지사항 목록) 페이지 이동
                                    this.state = {
                                        pageType: 'noticeList', // 기본 값 (공지사항 목록)
                                        noticeId: null
                                    }
                                    break;

                                default:
                                    this.state = {
                                        pageType: 'noticeList', // 기본 값 (공지사항 목록)
                                        noticeId: null
                                    }
                            }
                        } else {
                            this.state = {
                                pageType: 'noticeList', // 기본 값 (공지사항 목록)
                                noticeId: null
                            }
                        }
                    } else {
                        this.state = {
                            pageType: 'noticeList', // 기본 값 (공지사항 목록)
                            noticeId: null
                        }
                    }
                } else {
                    // notice 로 시작하는 엉뚱한 QueryParam 으로 접속하는 경우, 디폴트 (공지사항 목록) 페이지 이동
                    this.state = {
                        pageType: 'noticeList', // 기본 값 (공지사항 목록)
                        noticeId: null
                    }
                }
            }
        }
        this.isSideBarRendering = false; // 사이드바를 통해 접근 하는가
    }

    /**
     * 메뉴 사이드바 렌더링 여부 업데이트
     * @param flag
     */
    updateSideBarRendering(flag) {
        this.isSideBarRendering = flag;
    }

    /**
     * [공지사항 리스트 / 공지사항 상세 ...] 페이지 변경 호출
     * @param pageType :noticeList | noticeDetail/{noticeId}
     */
    setChangePageType = (pageType, noticeId) => {
        this.setState({
            pageType: pageType,
            noticeId: noticeId
        });
        this.updateSideBarRendering(false);
    }

    render() {
        const {pageType, noticeId} = this.state;
        let component = null;

        // console.log(" NoticeComponent > isSideBar Rendering: " + this.isSideBarRendering);

        if (this.isSideBarRendering === true) { // 사이드바 메뉴 > 공지사항 클릭
            component = <NoticeListComponent onPageTypeChange={this.setChangePageType} rightsHolders={this.props.rightsHolders}/>;
        } else if (pageType == null || noticeId == null) {
            component = <NoticeListComponent onPageTypeChange={this.setChangePageType} rightsHolders={this.props.rightsHolders}/>;
        } else {
            switch (pageType) {
                case 'noticeList':
                    component = <NoticeListComponent onPageTypeChange={this.setChangePageType} rightsHolders={this.props.rightsHolders}/>;
                    break;
                case 'noticeDetail':
                    component = <NoticeDetailComponent noticeId={noticeId} onPageTypeChange={this.setChangePageType} rightsHolders={this.props.rightsHolders}/>;
                    break;
                default:
                    component = <NoticeListComponent onPageTypeChange={this.setChangePageType} rightsHolders={this.props.rightsHolders}/>;
            }
        }

        return (
            <div className='container-fluid py-4'>
                <NoticeHeader pageType={pageType} isSideBarRendering={this.isSideBarRendering} rightsHolders={this.props.rightsHolders}/>
                <div className='row mt-1'>
                    {/* 메뉴 타입에 따라 렌더링할 컴포넌트 변경 */}
                    {component}
                </div>
            </div>
        );
    }
}
