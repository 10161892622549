import { APIConfig } from "../constans/APIConfig";

export class ErrorModel extends Error{
  constructor(err, errorType){
    super(err);
    this.ERR_TYPE_HTTP = 0;//http 통신간의 오류 
    this.ERR_TYPE_API = 1;//API 통신간의 정의된 오류
    this.ERR_TYPE_STATUS_CODE = 2;// 응답코드가 200이 아닌 경우
    this.ERR_TYPE_RUNTIME = 3;//실행간 오류

    this.ERROR_TYPE = errorType;
    this.status = null;

    this.API_ERROR_CODE = new APIConfig();

    this.ERROR = {
      ABORT : 'AbortError',//API 타임아웃
    }
    
    this.error = {
      type : null,
      name : null,
      message : null,
      code : null
    };

    this.HTTP_ERROR = {
      ABORT : {
        CODE : 408,
        MESSAGE : '네트워크 연결 지연되어 요청이 실패되었습니다.'
      }
    }

    this.STATUS_ERROR = {
      CLIENT_404 : {
        CODE : 404,
        MESSAGE : '요청한 리소스가 없거나 경로가 없습니다.'
      }
    }

    this.API_ERROR = {
      LOGIN_FAIL : {
        CODE : 1000,
        MESSAGE : '아이디 또는 패스워드가 올바르지 않습니다.'
      }
    }

    this.init(err);    
    return this.error;
  }

  setError(errroObject){
    if(errroObject){
      this.error.code = errroObject.CODE;
      this.error.message = errroObject.MESSAGE;
    }
  }

  init(error){
    console.error(' this.errorType ',  this.ERROR_TYPE ,error );

    switch(this.ERROR_TYPE){
      case this.ERR_TYPE_HTTP:
        if(error.name === this.ERROR.ABORT){
          this.setError(this.HTTP_ERROR.ABORT);
        }
      break;

      case this.ERR_TYPE_API:
        const apiError = this.API_ERROR_CODE.ERROR_CODE;
        
        if(error.CODE === apiError.LOGIN.FAIL){
          this.setError(this.API_ERROR.LOGIN_FAIL);
        }
      break;

      case this.ERR_TYPE_STATUS_CODE:
        if(error.status === this.STATUS_ERROR.CLIENT_404.CODE){
          this.setError(this.STATUS_ERROR.CLIENT_404);
        }
      break;

      case this.ERR_TYPE_RUNTIME:
      default:
      break;
    }
  }





}