/**
 * 페이지네이션 유틸 스크립트
 */
export class PaginationUtil {
    constructor() {
        this.pageLimit = 10; // 페이지 당 아이템 갯수 (기본 값: 10)
        this.pageCount = 10; // 이전 ~ 다음 사이 페이지 갯수 (기본 값: 10)
    }

    getPaginationReq = (pageNumber, pageLimitOption) => {
        const pageLimit = (pageLimitOption != null && pageLimitOption) ? Number(pageLimitOption.value) : this.pageLimit; // 페이지 보기 옵션 미선택 시, 기본 값 (10)
        const draw = (pageNumber != null && pageNumber) ? pageNumber : 1; // 선택 한 페이지 넘버 (기본 값: 1)
        const start = (draw - 1) * pageLimit;
        const length = pageLimit;
        const pageNo = parseInt(start / length) + 1;
        // TODO 필요 시에 추가

        const obj = {
            "draw": draw, // 선택 한 페이지 넘버
            "pageNo": pageNo, // 페이지당 보여주는 요소 갯수
            "limit": length
        };

        const query = Object.entries(obj)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
        return `?${query}`;
    }
}